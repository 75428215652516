.blog-post-container {
    display: flex;
    gap: 32px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.main-content {
    flex: 1;
    min-height: 800px;
}

.sidebar {
    width: 300px;
    min-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.more-blog-card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 12px;
    transition: transform 0.3s ease;
}

.more-blog-card:hover {
    transform: scale(1.02);
}

.more-blog-image {
    width: 75px;
    height: 75px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 16px;
}

@media (max-width: 768px) {
    .blog-post-container {
        flex-direction: column;
    }

    .sidebar {
        margin-top: 20px;
    }
}

.blog-post {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.title {
    font-size: 46px !important;
    margin: 48px 0px !important;
}

.post-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.post-content {
    margin-top: 20px;
}

.post-content p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: #333;
}

.post-content a {
    color: #007acc;
    text-decoration: none;
}

.post-content a:hover {
    text-decoration: underline;
}

.skeleton-title {
    height: 40px;
    background: #f0f0f0;
    margin-bottom: 20px;
    border-radius: 4px;
    animation: pulse 1.5s infinite;
}

.skeleton-image {
    height: 300px;
    background: #f0f0f0;
    margin-bottom: 20px;
    border-radius: 4px;
    animation: pulse 1.5s infinite;
}

.skeleton-content {
    height: 400px;
    background: #f0f0f0;
    border-radius: 4px;
    animation: pulse 1.5s infinite;
}

.skeleton-card {
    height: 100px;
    background: #f0f0f0;
    margin-bottom: 16px;
    border-radius: 4px;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}