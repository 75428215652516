input[type="date"] {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    width: 100%;
}

.select-time-slot p {
    font-weight: 600;
    font-size: 23px;
}

.select-time-slot .form-group.col-sm-12.form-group {
    padding-right: 0;
    padding-left: 0;
}

.select-date-apo {
    margin-bottom: 14px;
}

.time__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.select-time-slot .react-calendar {
    width: 100%;
}

.select-time-slot select option:disabled {
    background-color: #c7c4c4;
}

.schedule-model-select:focus{
    border: none;
}

.policy--accept {
    padding-left: 25px;
}