.banner__search {
    position: relative;
    /* z-index: 222222; */
    /* color: #fff; */
    text-align: center;
    width: 100%;
    /* background: #ffffff; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    padding: 0 1rem 0 1rem;
}

.form-group {
    margin-bottom: 0;
}

.Inner--Content {
    max-width: 100%;
    position: relative;
    margin: auto;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner__search h1 {
    font-size: 3rem;
    color: #3e3e3e;
    /* margin-bottom: 1rem; */
}

.banner__search form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    max-width: 800px;
    border: 1px solid #d1eff9;
    border-radius: 5px;
    /* padding: 1rem; */
}

.banner__search form .form-group {
    flex: 1 1 200px;
    min-width: 0;
}

.banner__search form .search--bar--list, .banner__search form .search--bar--location {
    position: absolute;
    overflow-y: auto;
    max-height: 250px;
    min-height: 250px;
    left: 0;
    top: 100%;
    z-index: 999;
    width: 100%;
    border: 1px solid #d1eff9;
    background: #ffffff;
    color: #333333;
    text-align: left;
}

/* .search--bar--list{
    z-index: 999;
} */

.banner__search form  .search--bar--location {
    padding-top: 0.5rem;
}

.banner__search form .search--bar--list h4 {
    background: #d1eff950;
    font-size: 16px;
    padding: 0.25rem 0.85rem;
}

.banner__search form ul li {
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 10px;
    padding: 0 0.85rem 0.5rem;
    border-bottom: 1px solid #d1eff950;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.banner__search form ul li:last-child {
    padding: 0 0.85rem 0.5rem;
    border-bottom: 0;
    margin-bottom: 0;
}

.banner__search form ul li img {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.banner__search form .btn-primary {
    border-radius: 5px;
    width: 50px;
    height: 50px;
    padding: 0;
    text-align: center;
    font-size: 25px;
    margin-right: 0px;
}

.banner__search form input {
    background: #ffffff;
    padding: 0 1rem;
    height: 50px;
    color: #3e3e3e;
    font-size: 1.2rem;
    border-radius: 0;
    border: 0;
    border-right: 1px solid #d1eff9;
    display: block;
    min-width: 250px;
}

.date--Calendar {
    position: absolute;
    top: 100%;
    left: 50%;
    background: #ffffff;
    color: initial;
    border-radius: 10px;
    box-shadow: 0 0 40px -20px #333333;
    transform: translateX(-50%);
    z-index: 111;
}

.banner__search form .form-group .date--Calendar svg {
    position: relative;
    left: auto;
    top: auto;
    fill: #3e3e3e;
}

.banner__search form .date--Calendar .fiwjyf {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
}

.fiwjyf li {
    padding: 0.5rem 0;
    font-size: 15px;
}

/* .banner__search .list-group {
    margin-top: 1rem;
    flex-flow: row wrap;
} */

.banner__search .list-group-item {
    background-color: #E8E1D9;
    color: #3e3e3e;
    margin-right: 0.5rem;
    border-radius: 100px;
    -wekit-border-radius: 100px;
    cursor: pointer;
    padding: .5rem 1.25rem;
}

.banner__search .list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
}

.banner__search .list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
}

.banner__search .search__results {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.banner__search .search__results h4, .search__results .accordion button {
    font-size: 1.2rem;
    display: block;
    width: 100%;
    text-align: left;
    box-shadow: none;
    padding: 0;
    text-decoration: none;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #78a1a7;
}

.search__results .accordion button {
    display: flex;
    border-bottom: 1px solid #d1eff950;
    padding-bottom: 0.5rem;
    align-items: center;
    justify-content: space-between;
}

.search__results .accordion button svg {
    font-size: 1rem;
    color: #31313180;
}

.banner__search .search__results ul {
    margin-bottom: 1rem;
}

.banner__search .search__results ul li {
    border-bottom: 1px solid #d1eff950;
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    line-height: 2;
    cursor: pointer;
}

.banner__search .search__results ul li:hover {
    color: #78a1a7;
}

.banner__search .search__results ul li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.word-transition {
    transition: opacity 0.5s ease-in-out;
}

.button--list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem;
}

.button--list .btn-secondary {
    border-radius: 100px;
    background: none;
    border: 1px solid #78a1a7;
    color: #78a1a7;
}

.button--list .btn-secondary:hover {
    background: #78a1a720;
    color: #78a1a7;
}

.button--list .dropdown-menu {
    padding: 1rem 0.5rem;
    border: 0;
    min-width: max-content;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
}

.button--list .business__list .list-group-item {
    border: 0;
    padding: .75rem 1.5rem;
}

.button--list .form-check, .button--list .form-check label {
    cursor: pointer;
}

.button--list .form-check input[type="checkbox"] {
    width: 17px;
    height: 17px;
    margin-top: 0.2rem;
    margin-left: -1.5rem;
}

.button--list .dropdown-toggle::after {
    display: none;
}

.button--list .form-check {
    padding-left: 0;
}

.button--list .form-check-label {
    cursor: pointer;
}

.button--list .form-check input[type=checkbox]+label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
}

.button--list .form-check input[type=checkbox] {
    display: none;
}

.button--list .form-check input[type=checkbox]+label::before {
    border: 1px solid #d1eff9;
    border-radius: 0.2rem;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 1px;
    width: 22px;
    height: 22px;
    margin-right: 0.8rem;
    color: transparent;
    transition: .2s;
    content: '';
}

.button--list .form-check input[type=checkbox]+label::after {
    display: inline-block;
    position: absolute;
    left: 5px;
    font-size: 14px;
    top: 2px;
    color: transparent;
    transition: .2s;
    content: '';
}

.button--list .form-check input[type=checkbox]:checked+label:after {
    content: "\2714";
    color: #fff;
}

.button--list .form-check input[type=checkbox]:checked+label:before {
    background-color: #d1eff9;
}



/* .search--page--main .business--map {
    flex: 1 1;
    max-width: 30%;
    background: #eeeeee;
    position: relative;
}

.search--page--main .business--map .map--frame {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
} */



.popular--Wrapper .sticky--title h2 {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
}

.popular--Wrapper .sticky--title h2::after {
    display: none;
}

/* .date--change {
    margin-left: auto;
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.date--change span {
    line-height: 25px;
}

.date--change .btn-primary, .date--change .btn {
    padding: 0;
    margin: 0 0 0 0.5rem;
    font-size: 20px;
} */

.cat--list--slider {
    width: 100%;
    max-width: 100%;
    background: rgb(249, 248, 247);
    border-style: solid;
    border-color: #eeeeee;
    border-width: 1px 0;
    padding: 1rem;
}

.cat--list--slider .slick-slider {
    padding: 0 1.5rem;
}

.cat--list--slider .slick-slide button {
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #78a1a7;
    color: #78a1a7;
    text-transform: capitalize;
    font-size: 15px;
    width: 100%;
    padding: 0.54rem 1rem;
    /* min-width: max-content;
    white-space: normal; */
}

.cat--list--slider .slick-prev, .cat--list--slider .slick-next {
    width: 40px;
    height: 40px;
    background: none;
    border-radius: 100px;
    z-index: 1;
}

.cat--list--slider .slick-prev:before, .cat--list--slider .slick-next:before {
    font-size: 22px;
    color: #3e3e3e;
}

.cat--list--slider .slick-prev:hover,
.cat--list--slider .slick-prev:focus,
.cat--list--slider .slick-next:hover,
.cat--list--slider .slick-next:focus {
    color: #78a1a7;
    background: none;
}

.cat--list--slider .slick-slider .slick-slide {
    padding: 5px 5px 0;
}

.cat--list--slider .slick-slide button:hover {
    background: #78a1a720;
    color: #78a1a7;
}

.business--card--grid {
    display: flex !important;
    flex-direction: column;
    width: 100%;
    /* gap: 1rem; */
}

.popular--Wrapper .business--card--grid .card {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    height: auto;
    border: 0;
    border-bottom: 1px solid #eeeeee;
    padding: 1rem;
    padding-top: 1.5rem;
    box-shadow: 1px 5px 20px -10px #00000050;
    -webkit-box-shadow: 1px 5px 20px -10px #00000050;
    border-radius: 0;
    -webkit-border-radius: 0;
    overflow: hidden;
    /* flex-flow: row wrap; */
    column-gap: 15px;
}

.business--card--grid .card .card-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.popular--Wrapper .business--card--grid p.card-text {
    display: block;
    flex: 1 1 100%;
    /* max-width: 35%; */
    margin: 0;
}

.business--card--grid .calendar--cal {
    flex: 1 1;
}

.business--card--grid .calendar--cal ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.business--card--grid .calendar--cal ul li {
    flex: 1 1 calc(14.28% - 0.5rem);
    min-width: 65px;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background: #d1eff9;
    color: #333333;
    margin-bottom: 10px;
    max-width: calc(14.333% - 10px);
}

.business--card--grid .calendar--cal ul li.no--appt {
    background: #eeeeee;
    color: #888888;
}

.business--card--grid .calendar--cal li span, .business--card--grid .calendar--cal li small {
    display: block;
}

.popular--Wrapper .business--card--grid .card-img-top {
    width: 130px;
    object-fit: cover;
    height: 130px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    object-position: center;
}

.search-single-business {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.search-single-business .card {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid #d1eff9;
    border-radius: 8px;
    overflow: hidden;
}

.search-single-business .card-img-container {
    flex: 0 0 150px;
    min-width: 150px;
    height: auto;
}

.search-single-business .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.search-single-business .card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.calendar--cal {
    width: 100%;
    margin-top: 1rem;
}

.calendar--cal ul {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

/*-=-=-=-=-=-=
Media Css
-=-==-=-=*/

@media (min-width:992px) and (max-width:1450px) {
    .Inner--Content {
        max-width: 100%;
    }

    .business--card--grid .card .card-body {
        flex-flow: row wrap;
        flex: 1 1 100%;
        max-width: calc(100% - 150px);
    }

    .business--card--grid .calendar--cal ul, .button--list {
        flex-flow: row nowrap;
        overflow-x: scroll;
        padding-bottom: 0.5rem;
    }

    .business--card--grid .calendar--cal ul::-webkit-scrollbar {
        height: 4px;
        background-color: #78a1a720
    }
    
    .business--card--grid .calendar--cal ul::-webkit-scrollbar-thumb {
        background-color: #33333350;
        border-radius: 3px
    }

    .button--list::-webkit-scrollbar {
        height: 4px;
        background-color: #78a1a720
    }
    
    .button--list::-webkit-scrollbar-thumb {
        background-color: #33333350;
        border-radius: 3px
    }

    .popular--Wrapper .business--card--grid p.card-text {
        max-width: 100%;
    }

    .business--card--grid .calendar--cal {
        width: 100%;
        max-width: 100%;
        margin-top: 1rem;
    }

    .business--card--grid .calendar--cal ul li {
        max-width: max-content;
        min-width: 65px;
    }
}

@media screen and (max-width:991px) {
    .banner__search {
        position: relative;
        padding: 0rem;
        /* padding-bottom: 3rem; */
        /* padding-top: 6rem; */
    }

    .banner__search form input{
        min-width: 0;
    }

    .Inner--Content {
        max-width: 100%;
        /* padding: 0 1rem; */
    }

    .banner__search h1 {
        font-size: 2.2rem;
    }

    .banner__search .list-group-item {
        padding: .5rem 1rem;
        margin-bottom: 0.5rem;
    }

    .banner__search form .form-group {
        flex: 1 1;
    }

    .popular--Wrapper .business--card--grid p.card-text {
        max-width: 100%;
    }

    .business--card--grid .card .card-body {
        flex-flow: row wrap;
        flex: 1 1 100%;
        max-width: calc(100% - 150px);
    }

    .business--card--grid .calendar--cal {
        margin-top: 1rem;
    }

    .button--list {
        padding-right: 0;
        padding-left: 0;
    }

    .business--card--grid .calendar--cal ul, .button--list {
        flex-flow: row nowrap;
        overflow-x: scroll;
        padding-bottom: 0.5rem;
    }

    .business--card--grid .calendar--cal ul::-webkit-scrollbar {
        height: 4px;
        background-color: #78a1a720
    }
    
    .business--card--grid .calendar--cal ul::-webkit-scrollbar-thumb {
        background-color: #33333350;
        border-radius: 3px
    }

    .business--card--grid .calendar--cal {
        width: 100%;
        max-width: 100%;
    }

    .business--card--grid .calendar--cal ul li {
        max-width: max-content;
        min-width: 65px;
    }
}

@media screen and (max-width:575px) {
    .popular--Wrapper .business--card--grid .card {
        max-width: 100%;
    }

    .banner__search form .form-group {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .banner__search form .btn-primary {
        border-radius: 0;
        width: 100%;
    }

    .banner__search .list-group {
        flex-flow: row nowrap;
        overflow-x: auto;
        width: 100%;
        align-items: center;
    }

  

    .business--card--grid .card .card-body {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .button--list {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0.5rem;
    }

    .business--card--grid .calendar--cal {
        margin-top: 1rem;
        width: 100%;
        max-width: 100%;
    }

 

    .button--list .btn-secondary {
        white-space: nowrap;
    }

    /* .date--change {
        margin-left: 0;
        justify-content: space-between;
        width: 100%;
    } */

    .popular--Wrapper .business--card--grid .card-img-top {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 768px) {
    /* .banner__search {
        padding: 2rem 1rem;
    } */

    .banner__search form {
        flex-direction: column;
    }

    .banner__search form .form-group {
        flex: 1 1 100%;
    }

    .business--card--grid .card {
        flex: 1 1 100%;
    }

    .calendar--cal ul {
        overflow-x: auto;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        -webkit-overflow-scrolling: touch;
    }

    .button--list {
        overflow-x: auto;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        -webkit-overflow-scrolling: touch;
    }

    .search-single-business .card {
        flex-direction: column;
    }

    .search-single-business .card-img-container {
        flex: 0 0 200px;
        min-width: 100%;
    }

    .calendar--cal ul {
        overflow-x: auto;
        flex-wrap: nowrap;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 0.5rem;
    }

    .calendar--cal ul::-webkit-scrollbar {
        height: 4px;
        background-color: #78a1a720;
    }
    
    .calendar--cal ul::-webkit-scrollbar-thumb {
        background-color: #33333350;
        border-radius: 3px;
    }
}

@media screen and (max-width: 576px) {
    .search-single-business .card-body {
        padding: 0.75rem;
    }
    
    .business--card--grid {
        gap: 0.75rem;
    }
    .popular--Wrapper .business--card--grid .card {
        flex-flow: row wrap;
    }
    .search-single-business-header {
        flex-flow: row wrap;
    }
}