/* Color Palette
Soft Blue: #d1eff9
Sage Green: #C2D8C5
Mint Green: #B6D1BF
Warm Beige: #E8E1D9 */

@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700;800;&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.awaiting-approval-row {
  background-color: orange !important;
}
.initials {
  color: #78c7d3;
  font-weight: 700;
}

html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: "Urbanist", sans-serif;
}


#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}


 /* Add this in your CSS file */
.custom-alert {
  font-size: 14px;
  padding: 5px;
  line-height: 1;
  height: 30px; /* Adjust height as needed */
  text-align: center;
}


/* body {
  font-family: "Urbanist", sans-serif;
  background: #ffffff;
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
} */

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1440px;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #5a6268;
  outline: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

a.pointer {
  cursor: pointer;
}

/* .btn {
  padding: 0.54rem 1.5rem;
} */

.btn-primary {
  color: #444444;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.3rem;
  background-color: #d1eff9;
  border-color: #d1eff9;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #444444;
  background-color: #d1eff9;
  border-color: #d1eff9;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #444444;
  background-color: #d1eff9;
  border-color: #d1eff9;
  box-shadow: none;
}

.btn-primary:hover {
  color: #444444;
  background-color: #d1eff9;
  border-color: #d1eff9;
}

.btn-outline-primary {
  border-color: #444444;
  color: #444444;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background: #444444;
  color: #ffffff;
  border-color: #444444;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #d1eff961;
  border-color: #d1eff961;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #444444;
  background-color: #d1eff961;
  border-color: #d1eff961;
  box-shadow: none;
  outline: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  background-color: #d1eff961;
  color: #444444;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: none;
}

.btn-secondary:focus-visible {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #191b1f;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.error-message {
  font-size: 12px;
  margin-left: 5px;
}

.link-get-paid a svg {
  fill: #333 !important;
}

.connect-stripe {
  /* text-align: center; */
  margin-top: 40px;
}

.connect-strip h5 {
  font-size: 1rem;
  font-weight: 600;
}

.account--info {
  /* display: block; */
  display: grid;
  /* align-content: center; */
  justify-content: center;
  padding: 2rem;
  background-color: #f7f7f7;
  border-radius: 10px;
}

.service-list-search {
  width: 100%;
  text-align: center;
  padding: 3rem;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

.service-list-search form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  border: 1px solid #d1eff9;
  border-radius: 50px;
  overflow: hidden;
  max-width: 60%;
  margin: auto;
}

.service-list-search form .form-group {
  width: 100%;
  position: relative;
  margin-bottom: 0px;
  flex: 1 1;
}

.service-list-search form input.form-control {
  border-radius: 0px;
  background: #ffffff;
  padding: 0 1rem;
  padding-left: 3rem;
  height: 45px;
  color: #3e3e3e;
  font-size: 1.2rem;
  border-radius: 0;
  border: 0;
  border-right: 0;
  display: block;
  min-width: 250px;
}

.service-list-search form .form-group svg {
  position: absolute;
  top: 16px;
  left: 20px;
}

.service-list-search form .btn-primary {
  border-radius: 100px;
  width: 50px;
  height: 50px;
  padding: 0;
  text-align: center;
  font-size: 25px;
  margin-right: 0px;
}

.stripe-cancel-main-wraper {
  margin-top: 120px;
  flex: 1;
  text-align: center;
}

.stripe-cancel-main-wraper .fade.alert.alert-danger.show {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 21px;
}

.stripe-cancel-main-wraper .fade.alert.alert-info.show {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 21px;
}

.table-striped button {
  font-size: 0.8rem;
  padding: 6px 18px;
}

.services-main-wraper-order {
  width: 100%;
  overflow-y: scroll;
}

.btn-primary:hover {
  background-color: d1eff9;
}

.btn-primary:disabled {
  color: #000;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.text-capitalize {
  text-transform: capitalize;
}

a.card-link span {
  color: #000;
}

.date-selector {
  float: right;
}

.places--list span {
  padding: 10px 10px;
  border-bottom: 0.4px solid #e2dfdf;
}

.places--list span:last-child {
  border-bottom: none;
}

.location--alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location--alert button {
  font-size: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

#react-tags-input {
  font-size: 14px;
}

.react-tags__listbox {
  max-height: 300px;
  position: absolute;
  background-color: #efefef;
  overflow: auto;
  z-index: 99999;
  width: 100%;
  border: 1px solid #ccc;
  /* Add border */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.react-tags__listbox-option {
  padding: 10px 10px;
  border-bottom: 0.4px solid #e2dfdf;
}

.react-tags__listbox .react-tags__listbox-option:last-child {
  border-bottom: none;
}

.react-tags__tag {
  border: 0.1px;
  padding: 10px 20px;
}

ul.react-tags__list {
  display: flex;
}

ul.react-tags__list li {
  margin-right: 10px;
}

.react__tags__suggestion li {
  padding: 10px;
  border-bottom: 0.4px solid #e2dfdf;
}

.react__tags__suggestion li:last-child {
  border-bottom: none;
}

.cursor-blocked {
  cursor: not-allowed;
}

.staff--modal {
  min-height: 40em;
}

.staff--modal--tab--content {
  overflow-y: scroll;
  overflow-x: hidden;
}

.certificate--light--box {
  height: 400px;
  width: auto;
}

.policy--modal {
  height: 30em;
  width: 100%;
  overflow: scroll;
}



/** ----- Profile Certificate CSS ----- **/
.dropdown-container {
  height: 50px;
  border: 0px;
  border-radius: 5px;
  background: #d0d0d0;
  justify-content: right;
  padding: 6px;
}

.product--display {
  background-color: bisque;
  padding: 10px 15px 10px 0;
  border-radius: 7px;
  margin: 10px 0 0;
  position: relative;
}

.product--display .form-group {
  margin-bottom: 0;
}

.product--display .product-remove {
  cursor: pointer;
  position: absolute;
  right: 5px;
  padding: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.product--display .product-remove svg {
  font-size: 20px;
}

.affiliate__Card .card-title {
  font-size: 24px !important;
  font-weight: bold !important;
}

/** ---- Multiple Select in Files ------ **/
.multi-select-disabled .clear-selected-button {
  display: none !important;
}

.multi-select-disabled .dropdown-container {
  background: #e9ecef !important;
  border-color: transparent !important;
}

/* .virtualIcon{
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
} */

.upload-progress-status {
  font-size: 10px;
  color: green;
}

@media (max-width: 991px) {
  .btn {
    padding: 0.5rem 1rem;
  }
}

.wallet-top-bar {
  background: #dce3dd;
  padding: 33px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.wallet-top-bar h1 {
  color: #333;
  font-weight: 600;
}

.wallet-popup-text {
  text-align: center;
  padding-bottom: 20px;
}

.wallet-popup-text svg {
  font-size: 30px;
  color: green;
  margin-bottom: 15px;
}

.calendar-no-appt {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.calendar-no-appt .no--scheduled--appt {
  padding: 10px 50px;
  border: 1px solid #d1eff9;
  background-color: #cce4e880;
  border-radius: 5px;
}

.calendar-no-appt .next--scheduled--appt {
  padding: 10px 50px;
  border: 1px solid #d1eff9;
  background-color: #d1eff9;
  border-radius: 5px;
}

.speciality .css-13cymwt-control {
  border: 0px;
  border-radius: 5px;
  background: #f6f4f4;
}

@media (max-width: 575px) {
  .wallet-top-bar {
    background: #dce3dd;
    padding: 33px 20px;
    border-radius: 10px;
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;
  }

  .wallet-top-bar button {
    margin-top: 15px;
  }

  .table-model-popup tr td {
    width: 50%;
    /* padding-bottom: 7px; */
    /* display: flex; */
  }

  .button-order-deatils-v button {
    margin-bottom: 10px;
  }

  .product--display .form-group + .form-group {
    margin-top: 0.5rem;
  }

  .product--display .product-remove {
    align-items: flex-start;
    padding-top: 10px;
  }

  .affiliate__Card .card-title {
    font-size: 16px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 896px) and (min-width: 768px) {
  .table-wallet {
    overflow: scroll;
    width: 56%;
    position: absolute;
  }
}

@media (max-width: 1073px) and (min-width: 768px) {
  .table-wallet-v2 {
    overflow: scroll;
    width: 59%;
    position: absolute;
  }
}

.table-model-popup table {
  width: 100%;
  margin-bottom: 20px;
}

.table-model-popup tr td {
  width: 50%;
  /* padding-bottom: 7px; */
}

.order-id-popup-o.modal-title.h4 {
  font-weight: 600;
}

.modal ~ .modal {
  background: rgba(0, 0, 0, 0.7);
}

.modal-body {
  max-height: 650px;
  overflow-y: auto;
}

.disablebutton {
  background-color: #dce3dd !important;
  color: #333 !important;
  border-color: #dce3dd !important;
}

.review-modal {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.review-modal .form-control {
  border: 1px solid #ced4da !important;
}

/** ------ Subscription layout ------ **/

.Subscription-text {
  font-weight: 700;
  margin-bottom: 15px;
}

.card-layout-Subscription {
  padding: 23px !important;
  text-align: center !important;
  cursor: default;
}

.card-layout-Subscription button {
  margin-top: 10px !important;
}

.card-layout-Subscription-wraper {
  margin-top: 0;
  margin-bottom: 40px;
}

.card-layout-Subscription .card-body {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
}

.active--subscription .card-layout-Subscription {
  background-color: #d1eff9;
}

.active--subscription .active--badge {
  position: absolute;
  top: 7px;
  /* right: 0; */
  z-index: 1;
  left: 25px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bolder;
  background: white;
  padding: 6px;
  border-radius: 10px;
}

.Subscription-text p small {
  text-transform: capitalize;
}

.active--subscription .Subscription-text,
.active--subscription .Subscription-text h4,
.active--subscription .Subscription-text h5,
.active--subscription .Subscription-text p small {
  color: #000;
  font-weight: 700;
}

.active--subscription .Subscription-text {
  /* color: #000; */
  font-weight: bolder;
}

.active--subscription .action--btn {
  background-color: #000;
  color: #ededee;
}
