.admin--content .chat--page {
    padding-top: 0;
}

.buyer__page {
    padding-top: 0;
    padding-bottom: 0;
}

.chat--page .container-fluid {
    width: 100%;
    max-width: calc(100% - 100px);
}

.buyer__page .container-fluid {
    width: 100%;
    max-width: 100%;
}

.chat--box {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #99999920;
    height: 100%;
}
/* 
.admin--content .chat--box {
    height: calc(100vh - 150px);
} */

.buyer__page .chat--box {
    border-radius: 0;
    height: calc(100vh - 68px); 
}

.chat__list {
    flex: 1 1 100%;
    /* max-width: 200px;
    min-width: 200px; */
    max-width: 350px;
    min-width: 350px;
    background: #ffffff;
}

.message__box {
    flex: 1 1;
    position: relative;
    background: #f8f8f8;
    max-width: 80%;
    border-left: 1px solid #99999930;
    border-right: 1px solid #99999930;
}

.empty__box {
    flex: 1 1;
    position: relative;
    font-size: 1.5rem;
    background: #f8f8f8;
    border-left: 1px solid #99999930;
    border-right: 1px solid #99999930;
}

.chat__info {
    flex: 1 1 100%;
    max-width: 200px;
    background: #ffffff;
    min-width: 200px;
}

.chat__list form {
    padding: 1rem;
    border-bottom: 1px solid #99999930;
}

.chat__list .form-group {
    margin: 0;
    position: relative;
}

.chat__list form svg {
    position: absolute;
    left: 18px;
    top: 16px;
    color: #999999;
}

.chat__list .form-control {
    /* border-radius: 50px;
    padding-left: 50px;
    border: 1px solid #eeeeee;
    background: #f7f7f770; */
    border: 1px solid #eeeeee;
    background: #f7f7f770;
    padding: 25px 20px;
}

.chat__list .list-group-item {
    border: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    column-gap: 15px;
    cursor: pointer;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 40px;
    min-height: 83px;
    border-bottom: 1px solid #99999930;
}

.chat__list .user__active {
    background: #7d969a20;
}

.chat__list .list-group-item:last-child {
    border-bottom: 0;
}

.chat__list .list-group-item p small {
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.chat__list .list-group-item .message__badge {
    position: absolute;
    right: 10px;
    top: 18px;
    border-radius: 100px;
    width: 18px;
    height: 18px;
    background: #75a0a7;
}

/* .chat__list .chat__search__list__wrapper {
    position: absolute;
    min-height: 350px;
    max-height: 350px;
} */

/* .chat__list .chat__search__list__wrapper .list-group-item {
    display: contents;
} */

.chat__list .list-group-item .chat__uicon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 100px;
    overflow: hidden;
    display: inline-block;
}

.chat__list .list-group-item .chat__uicon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat__list .list-group-item p {
    margin: 0;
}

/* .chat__list .list-group-item .chat__search__uname {
    margin-left: 8; 
    font-size:'14px';
    line-break: 'anywhere';
} */

.user__Status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    padding: 1rem;
    column-gap: 15px;
    border-bottom: 1px solid #99999930;
}

.message__list .list-group-item,
.message__list .list-group-item {
    border: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0 1rem 1rem;
    background: none;
}

.user__Status .badge {
    width: 8px;
    height: 8px;
    color: transparent;
    border-radius: 100px;
    display: inline-block;
}

.message__list .list-group-item p small,
.user__Status p small {
    display: block;
    line-height: 1.2;
    font-weight: 600;
    color: #75a0a7;
}

.message__list .list-group-item .chat__uicon,
.user__Status .chat__uicon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 100px;
    overflow: hidden;
    display: inline-block;
}

.message__list .list-group-item .chat__uicon img,
.user__Status .chat__uicon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.message__list .list-group-item {
    align-items: flex-end;
}

.message__list .list-group-item p {
    margin: 0;
}

.buyer__page .message__list .list-group {
    height: calc(100vh - 235px);
    /* height: 100vh; */
    overflow-y: auto;
}

.buyer__page .chat__list .list-group {
    max-height: calc(100vh - 155px);
    overflow-y: auto;
}

.admin--content .chat--page .message__list {
    padding-bottom: 0;
}

.admin--content .chat--page .message__list .list-group {
    /* ----------- By Anmol ---------- */
    /* max-height: 100%; */
    padding-bottom: 0;
}

.admin--content .chat--page .chat__list .list-group {
    max-height: calc(100vh - 155px);
    overflow-y: auto;
}

/* .admin--content .chat--box {
    height: calc(100vh - 155px);
} */

.message__list .list-group {
    padding-bottom: 48px;
    /* padding-bottom: 70px; */
    position: relative;
}

.message__list .list-group::-webkit-scrollbar,
.chat__list .list-group::-webkit-scrollbar {
    width: 3px;
}

.message__list .list-group::-webkit-scrollbar-track,
.chat__list .list-group::-webkit-scrollbar-track {
    background-color: transparent;
}

.message__list .list-group::-webkit-scrollbar-thumb, .chat__list .list-group::-webkit-scrollbar-thumb {
    background-color: #99999950;
}

.message__list .list-group::-webkit-scrollbar-track, .chat__list .list-group::-webkit-scrollbar-track,
.message__list .list-group::-webkit-scrollbar-thumb, .chat__list .list-group::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.message__list .list-group-item p {
    margin: 0;
    background: #75a0a710;
    padding: 1.1rem;
    border-radius: 20px 20px 20px 0;
    position: relative;
}

.message__list .list-group-item p video, 
.message__list .list-group-item p img {
    max-width: 300px;
    width: 300px;
    height: auto;
}

.message__list .list-group-item p .message__actions, .message__list .list-group-item p .file__download {
    position: absolute;
    right: 0;
    top: 0;
    background: #75a0a7;
    padding: 8px 8px 8px 5px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 5px;
    opacity: 0;
}

.message__list .list-group-item p .file__download {
    right: 39px;
}

.message__list .list-group-item p:hover .message__actions, .message__list .list-group-item p:hover .file__download {
    opacity: 1;
}

.message__list .list-group-item p .message__actions svg,
.message__list .list-group-item p .file__download svg {
    color: #ffffff;
    cursor: pointer;
    width: 20px;
    height: auto;
    margin-left: 5px;
}

.message__list .list-group-item p .file__download svg {
    width: 22px;
}

/* .message__list .list-group-item:nth-child(even) {
    margin-left: auto;
    margin-top: 0.5rem;
    flex-flow: row-reverse;
}

.message__list .list-group-item:nth-child(even) p {
    background: #75a0a720;
    border-radius: 20px 20px 0 20px;
}

.message__list .list-group-item:nth-child(even) p small {
    text-align: right;
} */

.message__list .list-group-item.sender {
    margin-left: auto;
    margin-top: 0.5rem;
    flex-flow: row-reverse;
}

.message__list .list-group-item.sender p {
    background: #75a0a740;
    border-radius: 20px 20px 0 20px;
}

.message__list .list-group-item.sender p small {
    text-align: right;
}

.type__message {
    background: #ffffff;
    padding: 1rem;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 333;
}

.type__message .form-group {
    display: flex;
    align-items: center;
    margin: 0;
    column-gap: 15px;
}

.type__message .form-group label {
    margin: 0;
    cursor: pointer;
}

.type__message .btn-primary {
    padding: 0.5rem 1rem;
}

.chat__info {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
}

.chat__info .people__info {
    text-align: center;
    margin-bottom: 2rem;
}

.chat__info .people__info p strong {
    display: block;
    font-size: 1.5rem;
}

.chat__info .people__info p small {
    display: block;
    color: #999999;
    line-height: 1;
}

.chat__info .people__info .chat__uicon {
    width: 180px;
    min-width: 180px;
    height: 180px;
    margin-bottom: 10px;
    border-radius: 100px;
    overflow: hidden;
    display: inline-block;
    border: 8px solid #75a0a760;
}

.chat__info .people__info .chat__uicon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat__info .list-group {
    text-align: center;
}

.chat__info .list-group-item {
    border: 0;
    padding: 0.5rem 0;
    border-radius: 0;
    color: #999999;
}

.chat__info .list-group-item svg {
    min-width: 22px;
}

.back__arrow {
    display: none;
}

.back__arrow svg {
    font-size: 25px;
}

.loadmore__message__loader {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    background-color: #75a0a7;
    color:#eeeeee;
}

.chat__date{
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    z-index: 111;
}

.chat__date span {
    background-color: #75a0a7;
    color: #ffffff;
    padding: 5px 20px;
    border-radius: 50px;
}

.no_result_h6 {
    text-align: center;
    padding: 1rem;
}

.message__list {
    position: relative;
}

.attached__files {
    /* position: absolute; */
    left: 0;
    bottom: 65px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 222;
    flex-flow: row wrap;
    max-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background: rgba(255, 255, 255, 1);
}

.attached__file {
    padding: 1rem;
    min-width: 100%;
    border-bottom: 1px solid #75a0a730;
    overflow: hidden;
    position: relative;
    flex: 1 1;
    display: flex;
    align-items: center;
}

.attached__file:last-child {
    border-bottom: 0;
    margin-bottom: 0;
}

.attached__file div {
    cursor: pointer;
}

.attached__file svg {
    cursor: pointer;
    margin-left: auto;
    padding: 0.5rem;
    width: 30px;
    height: auto;
    color: red;
    background: #ffffff;
}

.format-message {
    font-family: inherit;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    display: inline-block;
    line-height: 1.4;
}

/** --------------------- Search List -----------------------------**/
.chat__list .chat__list__heading {
    font-size: 14px;
    padding:10px;
    margin-bottom: 0;
}

.chat__list .chat__search__list__wrapper {
    /* overflow-y: auto;
    max-height: 328px;
    z-index: 9999; */

    overflow-y: auto;
    max-height: 328px;
    z-index: 9999;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #fff;
}

.chat__list .chat__search__list__wrapper .chat__search__list__item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    justify-content: space-between;
}

.chat__list .chat__search__list__wrapper .chat__search__list__item__left {
    display: flex;
    align-items: center;
}

.chat__list .chat__search__list__wrapper .chat__search__list__item:hover{
    background-color: #ededed;
}   

.chat__list .chat__search__list__wrapper .chat__search__list__item {
    border-bottom: 1px solid #ccc; /* Example border */
}

.chat__list .chat__search__list__wrapper .chat__search__list__item:last-child {
    border-bottom: none;
}

.chat__list .chat__search__list__wrapper .chat__search__list__item .chat__search__uicon{
    
    overflow: hidden;
}

.chat__list .chat__search__list__wrapper .chat__search__list__item .chat__search__uicon img{
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

.chat__list .chat__search__list__wrapper .chat__search__list__item .chat__search__uname{
    margin-left: 8px;
}

.messanger-progress-bar .progress-bar {
    background-color: #75a0a7;
}

@media screen and (max-width:991px) {
    .chat__list {
        max-width: 100%;
        min-width: 100%;
    }

    .back__arrow {
        display: inline-block;
    }
    
    .chat--box {
        flex-flow: row nowrap;
    }

    .admin--content .chat--box {
        height: calc(100vh - 0px);
    }

    .message__box {
        max-width: 100%;
        border-left: 0px solid #99999930;
        border-right: 0px solid #99999930;
    }
    
    .toggleChat {
        position: relative;
        transform: translateX(-100%);
        flex: 1 1;
        max-width: 100%;
        min-width: 100%;
    }

    .chat__info {
        display: none;
    }
}