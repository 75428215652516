.App-footer {
    padding:  16px;
    background: #e3f8ff;
    border-top: 0;
}

.App-footer .brand--Name {
    padding-right: 5rem;
}

.App-footer .brand--Name a {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.App-footer .brand--Name img {
    max-width: 60px;
}

.App-footer .brand--Name span {
    text-transform: uppercase;
    font-weight: 700;
    color: #aed9e0;
    margin-left: 1rem;
    font-size: 2rem;
}

.App-footer h3 {
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 12px;
}

.App-footer .list-group-item {
    background: none;
    border: 0;
    padding: 0;
    margin-bottom: 12px;
    color: #3e3e3e;
    font-weight: 500;
}

.App-footer .list-group-item:hover {
    color: #000000;
}

.App-footer .list-group-item svg {
    fill: #3e3e3e;
    margin-right: 0.2rem;
    font-size: 75%;
}

.App-footer .copyright--Text {
    text-align: center;
    border-top: 1px solid #3e3e3e10;
    padding-top: 1rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.App-footer .copyright--Text .list-group {
    flex-flow: row nowrap;
    margin-left: auto;
}

.App-footer .copyright--Text .list-group-item {
    margin: 0;
    margin-left: 1rem;
}

.App-footer .copyright--Text p {
    margin: 0;
    color: #3e3e3e;
}

/*-=-=-=-=
Media css-=-=-=*/

@media (min-width:992px) and (max-width:1199px) {
    .App-footer .brand--Name {
        padding-right: 0rem;
    }
}
@media screen and (max-width:991px) {
    .App-footer .brand--Name {
        padding-right: 0;
        margin-bottom: 2rem;
    }

    .App-footer .brand--Name span {
        font-size: 1.8rem;
    }
}