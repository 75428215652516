

.custom-swal-title {
  font-size: 18px;
}

.custom-swal-text {
  font-size: 16px;
}

.section {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
}
.bg-color {
    background-color: #fbfbfb;
}

.verify-url{
    color: blue;
    word-break: break-all;
}

/** ---- button css ---- **/
.button-row {
    width: 100%;
    margin: 1rem;
  }
  
  .button-row .btn {
    /* flex: 1; */
    margin: 5px;
  }
  
  @media (min-width: 769px) {
    .button-row {
      justify-content: space-between;
    }
  
    .button-row .btn {
      width: auto;
      margin: 0 10px;
    }
  
    .button-row .ml-auto {
      margin-left: auto; /* This will push the button to the right */
    }
  }
  
  @media (max-width: 768px) {
    .button-row {
      flex-direction: column;
    }
  
    .button-row .btn {
      width: 100%;
      margin: 5px 0;
    }
  }
  
  