.waiver--upload--file{
    padding: 1.25rem 4rem;
    border: 1px dashed;
    border-radius: 5px;
}

.waiver-policy-document {
    display: grid;
}

.MuiMenu-list, .MuiInputBase-root{
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{ */
    color: #000 !important;
}
