/* Color Palette
Soft Blue: #d1eff9
Sage Green: #C2D8C5
Mint Green: #B6D1BF
Warm Beige: #E8E1D9 */

.App-header {
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    /* box-shadow: 0 0 5px #33333320; */
    background: #ffffff;
    /* z-index: 111; */
}
.navbar {
    padding: 0 1rem;
}

.navbar-brand, .navbar-brand a {
    display: flex;
    align-items: center;
}

.navbar-brand img {
    max-width: 60px;
}

.navbar-brand span {
    text-transform: uppercase;
    font-weight: 700;
    color: #aed9e0;
    margin-left: 1rem;
    font-size: 2rem;
}

.navbar-light .navbar-nav {
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.navbar-light .navbar-nav .nav-link {
    color: #1e1e1e;
    /* padding: 1.3rem 1rem; */
    background: transparent;
    font-weight: 600;
    font-size: 15px;
    margin-left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    width:max-content;
    border-radius: 5px;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #444444;
    background: #d1eff9;
    /* background: #C2D8C5; */
}

.navbar .dropdown-toggle {
    background: none;
    border: 0;
    box-shadow: none;
    border-radius: 100px;
    padding: 0;
    margin-left: 1rem;
}

.navbar .dropdown-toggle:active,.navbar .dropdown-toggle:hover, .navbar .dropdown-toggle:focus-visible {
    background: none;
}

.navbar .btn-primary:not(:disabled):not(.disabled).active, 
.navbar .btn-primary:not(:disabled):not(.disabled):active, 
.navbar .show>.btn-primary.dropdown-toggle {
    background: none;
}

.navbar .dropdown-toggle:after {
    display: none;
}

.navbar .dropdown-toggle img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    /* width: 100px; */
    /* height: 38px; */
    /* object-fit: cover; */
    /* object-position: center; */
    border-radius: 50%;
}

.navbar .dropdown-menu {
    left: auto;
    min-width: 0;
    padding: 0;
    right: 0;
}

.navbar .dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .85rem 1.2rem;
    clear: both;
    font-size: 0.875rem;
    font-weight: 400;
    color: #444444;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar .dropdown-item svg {
    width: auto;
    height: auto;
    font-size: 1.1rem;
    margin-right: 0.5rem;
}

.header-main .dropdown-item a svg{
    fill: #333!important;
}

.App-header .app-header-seller .nav-link:hover{
    color: #444444;
    background: #d1eff9;
    border-radius: 5px;
}
/*-==-=--=-=-
Media Css
-=-==-=-=-=*/
/* headerStyle.css */


@media screen and (max-width:1199px) {
    .navbar-light .navbar-brand {
        flex: 1 1;
        margin-right: 0;
    }
}
@media screen and (max-width:991px) {
    .navbar-light .navbar-nav {
        align-items: flex-start;
    }
    
    .navbar-light .navbar-nav .nav-link {
        padding: 1rem 0rem;
        margin-left: 0;
        width: 100%;
        border-bottom: 1px solid #d1eff970;
    }

    .navbar-nav .dropdown-menu {
        position: static !important;
        width: 100% !important;
        float: none;
        border: 0;
        box-shadow: none;
        background: #f7f7f7;
    }

    .navbar-light .navbar-nav .dropdown {
        display: block;
        width: 100%;
    }

    .navbar-light .navbar-nav .nav-link:last-child {
        border-bottom: 0;
    }

    .navbar-light .navbar-nav .nav-link:hover, 
    .navbar-light .navbar-nav .active>.nav-link, 
    .navbar-light .navbar-nav .nav-link.active, 
    .navbar-light .navbar-nav .nav-link.show, 
    .navbar-light .navbar-nav .show>.nav-link {
        color: #d1eff9;
        background: none;
    }
}

@media screen and (max-width:575px) {
    .navbar-light .navbar-brand {
        flex: 1 1;
        margin-right: 0;
    }

    .navbar-toggler {
        padding: .2rem .5rem;
    }

    .navbar-brand span {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:360px) {
    .navbar-brand span {
        font-size: 1rem;
        margin-left: 0.5rem;
    }

    .navbar-brand img {
        max-width: 45px;
    }
}