.main-staff-update-pass a.company--brand {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    background: #c2d8c57d;
    height: 100%;
    border-radius: 1rem 0 0 1rem;
    padding: 33px 20px;
}
.main-staff-update-pass.container {
    padding: 180px 20px;
}
.main-staff-update-pass a.company--brand img {
    max-width: 350px;
}
.main-staff-update-pass a.company--brand span {
    color: #d1eff9;
    text-transform: uppercase;
    display: block;
    text-align: center;
    font-size: 3rem;
}
.main-staff-content-up-pass {
    background: #ffffff;
    padding: 3rem;
    box-shadow: 5px 5px 50px -30px #000000;
    border-radius: 0 1rem 1rem 0;
    /* height: 100%; */
}
.main-staff-content-up-pass input.form-control {
    height: 50px;
    border: 0px;
    border-radius: 5px;
    border: 1px solid #c3baba;
}
.main-staff-content-up-pass h2 {
    font-weight: 600;
    margin-bottom: 25px;
}