/* .cropper-container {
    position: relative;
    width: auto;
    height: 300px;
}

.circle-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.8); 
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5); 
    pointer-events: none;
} */


/* Add circular style for cropper preview */
.img-preview {
    border-radius: 50%;
    width: 200px; /* Adjust size as per your design */
    height: 200px; /* Adjust size as per your design */
    overflow: hidden;
    border: 2px solid black; /* Optional, for better visibility */
  }
  
  /* Ensure the cropped image itself has a circular shape */
  .cropper-view-box,
  .cropper-face {
    border-radius: 50% !important;
  }