.blog-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 46px !important;
  text-align: center;
  /* margin-bottom: 40px; */
  margin: 0px 0px 48px 0px !important;
}

.blog-card {
  height:auto;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-row {
  height:auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.image-container {
  flex: 1;
  min-width: 200px;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  flex: 2;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-content h2 {
  margin: 0 0 10px;
  font-size: 1.5rem;
}

.blog-content p {
  margin: 0;
  color: #555;
}

.blog-content a {
  text-decoration: none;
  color: #333;
}

.blog-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .card-row {
    flex-direction: column;
  }

  .image-container {
    min-width: 100%;
  }

  .content-container {
    padding: 10px;
  }
}


/* .blog-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 32px !important;
  text-align: center;
  margin-bottom: 40px;
}

.blog-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-content h2 {
  margin: 0 0 10px;
  font-size: 1.5rem;
}

.blog-content p {
  margin: 0;
  color: #555;
}

.blog-content a {
  text-decoration: none;
  color: #333;
}

.blog-content a:hover {
  text-decoration: underline;
} */


/* 
.blog-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  .title{
    font-size: 32px !important;
  }
  .blog-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 15px;
    flex: 1;
  }
  
  .blog-content h2 {
    margin: 0 0 10px;
    font-size: 1.5rem;
  }
  
  .blog-content p {
    margin: 0;
    color: #555;
  }
  
  .blog-content a {
    text-decoration: none;
    color: #333;
  }
  
  .blog-content a:hover {
    text-decoration: underline;
  }
   */