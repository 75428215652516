.search--page--main {
  /* flex-flow: row wrap; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 20px; /* Adds some spacing between sidebar and results */
  background: #fff; /**  White Background for search page */
}

/** ----- Search Filter Sidebar Frame CSS ------- **/
.search--page--main .search--filter--main {
  max-width: 400px;
  /* Make the sidebar fill the full viewport height */
  height: 100%; 
  /* Enable vertical scrolling if content overflows */
  overflow-y: auto;
  /* margin: 5px 0px; */
  border-right: 1px solid #ededed;   

  padding-bottom:3rem;
}

.search--page--main .search--filter--main .search--filter--frame {
  position: sticky;                                                                                                                            
  position: -webkit-sticky;
  padding: 20px 10px;
  top: 0;
}

/* Toggle Button for Sidebar */
.sidebar-toggle-btn {
  display: none; /* Hidden by default */
}

.sticky--title {
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
  /* margin: 1rem 0 0; */
  padding: 1rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #ffffff;
  z-index: 111;
}



.search--page--main .search__results {
  flex: 1 1;
  position: relative;
  max-width: calc(100% - 440px);
}

/* .search__results .business__list {
  max-height: 300px;
  overflow: scroll;
} */

/** -------- Dates Change Buttons Left Right ----------- **/
.date--change {
  margin-left: 16px;
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.date--change span {
  line-height: 25px;
}

.date--change .btn-primary, .date--change .btn {
  padding: 0;
  margin: 0 0 0 0.5rem;
  font-size: 20px;
}

/** -------- Dates Change Buttons Left/Right ----------- **/


.button--list .btn-virtual {
  border-radius: 100px;
  background: none;
  border: 1px solid #78a1a7;
  color: #78a1a7;
  text-transform: capitalize;
}

.button--list .btn-virtual-active {
  background: #78a1a7;
  color: #fff;
}

.distance-range {
  max-width: 25rem;
  min-width: 20rem;
  padding-top: 30px;
}

.distance-range h4 {
  font-size: 14px;
}

.booking--modal .modal-dialog {
    max-width: 610px;
}

.modal--service--business {
    flex-flow: row wrap;
    border: 0;
}

.modal--service--business img.card-img-top {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.modal--service--business .card-body {
    padding-top: 0;
    padding-bottom: 0;
}

.modal--service--business .card-title {
    margin-bottom: 0;
    font-size: 16px;
}

.modal--service--business .card-text {
  font-size: 14px;
}

.modal--service--business .card-text {
  font-size: 14px;
}
.modal--service--business .item__rating {
  font-size: 14px;
}

.booking--modal .sticky--app {
    margin: 0;
    padding: 1rem 0 .5rem 0;
    position: sticky;
    position: -webkit-sticky;
    top: -33px;
    background: #ffffff;
    z-index: 111;
}

.booking--modal .calendar--cal ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(auto, auto);
}

.booking--modal .calendar--cal ul li {
    background: #d1eff9;
    color: #111111;
    border-radius: .25rem;
    cursor: pointer;
    padding: .5rem .75rem;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.booking--modal .calendar--cal ul li:hover {
    background: #78a1a7;
    color: #ffffff;
}

.booking--modal .calendar--cal ul li.disable,  .booking--modal .calendar--cal ul li.disable:hover {

  background: #a4a4a4;
  color: #ededed;
  cursor: default;
}

.card-virtual-icon{
  height: 50px;
  width: 50px;
}

.search-single-business-header {
  
  /* display: grid; */
  /* justify-items: center; */
}

.date--change .react-calendar {
  position: absolute;
  top: 56px;
}

/** -------------  Geolocation Section Styling Updated on 14-December-2024 ------------------- **/

.find__near {
  margin-top: 20px; 
}

.location__search {
  /* background-color: aqua; */
}


/* .distance-range {
  padding-top: 30px;
}

.distance-range h4 {
  font-size: 14px;
} */

/** -------------  End Code ------------------- **/

@media (min-width: 992px) { /* Hide on screens above 991px */
  .sticky--title--child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* Media Query for non-desktop screens (max-width: 991px) */
@media screen and (max-width:1119px) {
  .filter--section--title--actions{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .sticky--title {
      display: flex;
      padding: 1rem 0;
      justify-content: space-between;
      align-items: center;
  }

  .sticky--title h1,
  .sticky--title h2,
  .sticky--title h3,
  .sticky--title h4,
  .sticky--title h5,
  .sticky--title h6 {
      margin-bottom: 0;
  }

  .search--page--main {
    padding: 10px 10px
  }

  .search--page--main .search__results {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .sidebar-toggle-btn {
    display: block;
    font-size: 32px;
    color: #73a5b5;
    /* position: absolute; */
    /* top: 5px; */
    margin: 0;
    /* right: 5px; */
  }

  .search--page--main .search--filter--main {
    display: none; /* Hide sidebar by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 80%; /* Sidebar width on mobile */
    max-width: 100%; /* Limit the max width */
    height: 100vh;
    background: white;
    z-index: 1000; /* Ensures it overlays other content */
    transform: translateX(-100%); /* Initially hidden off-screen */
    transition: transform 0.3s ease; /* Smooth slide-in effect */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
  }
  
  /* When sidebar is open */
  .search--page--main .search--filter--main.open {
    display: block;
    width:100%;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(0); /* Slide sidebar into view */
  }

  /* Overlay when sidebar is open */
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Behind the sidebar */
  }

  .sidebar-overlay.show {
    display: block;
  }
}

/* Media Query for non-desktop screens (max-width: 575px) */
@media (max-width: 575px) {

  .date--change {
    display: flex;
    justify-content: center;
    font-size: 14px;
    column-gap: 0;
    width:100%
  }

  .search-single-business-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    text-align: center; /* Center text content */
    width: 100%;
  }
  
  .card-virtual-icon {
    height: 40px; /* You can adjust the size for smaller screens */
    width: 40px;
  }
}

@media (max-width: 768px) { /* Tablet and below */
  .search--page--main {
    padding: 10px 10px
  }
}

.search-single-business {
    border: 1px solid #eee;
    border-radius: 8px; /* Slightly rounded corners */
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); /* Smoother transition */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04),
                0 1px 4px rgba(0, 0, 0, 0.02);
    background: white;
    padding: 16px;
}

.search-single-business:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(62, 130, 154, 0.12),
                0 4px 8px rgba(62, 130, 154, 0.06),
                0 0 2px rgba(62, 130, 154, 0.02);
    border-color: rgba(62, 130, 154, 0.15);
}

/* Enhance image transition */
.search-single-business-header img {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.search-single-business:hover .search-single-business-header img {
    transform: scale(1.03);
}

/* Update existing styles */
.business--card--grid {
    display: grid;
    gap: 24px; /* Increased spacing between cards */
    padding: 16px;
}