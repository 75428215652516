.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0; /* Remove unnecessary margin */
}

.badge-style {
    margin-right: 20px;
    font-size: 14px;
    color: #1976d2; /* Material UI primary color */
    font-weight: bold;
}

.loading-container {
    text-align: center;
    margin-top: 16px;
}

.list-container{
    padding-top: 0%;
    padding-bottom: 0%;
}

.list-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: px 0; 
    margin: 0; /* Remove any extra margins */
}

.category-title {
    color: black;
    margin-bottom: 0; /* Remove bottom margin to close the gap */
}

.subcategory-item {
    color: black;
    margin: 0; /* Remove margin to remove the gap */
}

.MuiAccordionDetails-root {
    padding: 0; /* Remove inner padding from accordion details */
}

.MuiList-root {
    padding: 0; /* Remove list padding */
}