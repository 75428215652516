div.app--loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .5);
}

div.loader-main{
    width: 100%;
    text-align: center;
}

div.loader-main div{
    display: flex;
    justify-content: center;
}  
  
div.loader-main svg, div.app--loader svg{
    fill:#6aa4ad;
}