.featuresContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: 'Poppins', sans-serif !important;
    z-index: 1;
}

.featuresItemsLg {
    display: flex;
    background-color: white;
    color: black;
    /* width: 100%; */
    /* Default to full width */
    max-width: 300px;
    /* Limit the maximum width to 290px */
    padding: 35px;
    border-radius: 20px;
    margin: 8px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease;
}

.serviceTitle {
    /* margin: 12px 0; */
    /* width:80%; */
    font-family: 'Poppins', sans-serif !important;
}

.serviceBody {
    /* margin: 12px 0; */
    /* width:90%; */
    font-family: 'Poppins', sans-serif !important;
}