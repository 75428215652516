/** ----- Search filter Sidebar CSS --------- **/
.search-filter-sidebar .filter--section--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0px;
}

.filter-section {
    padding: 1rem 0rem;
    border-bottom: 1px solid #ededed;
}

.search-filter-sidebar .filter-section .filter--section--subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.search-filter-sidebar .filter-section .filter--section--subtitle h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
}

.search-filter-sidebar .filter-section .filter--section--subtitle .clear-filter {
    font-size: 14px;
    padding: 5px 20px
}

.search-filter-sidebar .filter-section .filter--section--body .list-group-item {
    border: 0;
    padding: 0.25rem 1.25rem
}


