.custom--suggested--search .suggestion--search--profile {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.custom--suggested--search .suggestion--search--name {
    text-transform: capitalize;
    font-weight: bold;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
}

.custom--suggested--search {
    position: relative;
}

.custom--suggested--search .list-group {
    max-height: 400px;
    overflow-y: scroll;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 111;
}

.custom--suggested--search .form-inline {
    position: relative;
}

.custom--suggested--search .cross-icon {
    position: absolute;
    right: .5rem;
    top: auto;
    opacity: .5;
    cursor: pointer;
}

.custom--suggested--search .form-control {
    padding-right: 2rem;
    flex: 1 1;
}

.custom--suggested--button {
    position: sticky;
    position: -webkit-sticky;
    bottom: -1px;
    background: #ffffff;
    text-align: center;
    padding-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, .1);
}

.edit--page .saved--suggested--list .card {
    padding: 1rem;
}

.popular--Wrapper .saved--suggested--list .card .card--media {
    align-items: center;
    padding: 0.5rem;
}

.popular--Wrapper .saved--suggested--list .card--media .card-title {
    max-width: calc(100% - 92px);
}