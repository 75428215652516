.staff-img img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}

.staff-main-wraper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.staff-main-wraper h3 {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
}

.staff-img {
    margin-right: 16px;
}

.staff-main-wraper .rating-num {
    font-size: 16px;
    margin-left: 10px;
    line-height: 0;
}

span.style-module_starRatingWrap__q-lJC {
    margin-top: 1px;
}

.content-staff p {
    margin-top: 20px;
}

.main-wraper-staff {
    background: #fff;
    padding: 28px;
    margin-bottom: 1rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.our-staff-heading {
    font-weight: 600;
    font-size: 30px;
    text-transform: capitalize;
}

.our-staff-main-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.model-staff-detals .staff-img-01 img {
    height: 229px;
    width: 270px;
    object-fit: cover;
}

.staff-img-01 {
    /* width: 167%; */
    margin-right: 40px;
}

.model-staff-detals .staff-main-wraper h3 {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: capitalize;
}

.model-staff-detals .staff-main-wraper p {
    margin-top: 15px;
}

.model-staff-detals .staff-main-wraper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.r-m-w img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    margin-right: 15px;
}

.r-m-w {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main-content-review {
    margin-top: 15px;
}

.main-content-review h5 {
    font-weight: 600;
}

.review-rating-date h5 {
    font-weight: 600;
}

.review-main-model {
    padding-top: 20px;
    border-bottom: 1px solid #cbc4c4;
    padding-bottom: 24px;
}

.Reviews-h-m {
    margin-top: 20px;
}

.Reviews-h-m h3 {
    font-size: 22px;
}

span.Verified {
    color: green;
    font-size: 16px;
    margin-left: 10px;
}

.modal-header {
    border-bottom: 0px;
}

.review-main-scroll {
    height: 320px;
    overflow-x: scroll;
}

.email-verified-staff {
    margin-top: 10px;
    color: green;
    font-size: 19px;
}

.email-verified-staff svg {
    font-size: 21px;
}

.staff-action-main-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.staff-action-main-wraper button {
    background-color: #fff;
    border: 1px solid #d9d0d0;
    color: #333;
    padding: 5px 8px;
}

.staff-action-main-wraper button:hover, .staff-action-main-wraper button:focus {
    background: #333;
    box-shadow: none;
    color: #ffffff;
}

.staff-action-main-wraper .btn-success:not(:disabled):not(.disabled).active, 
.staff-action-main-wraper .btn-success:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #333333;
    border-color: transparent;
}

.staff-action-main-wraper .dropdown-menu {
    border: 1px solid #33333310;
}

.staff-action-main-wraper .dropdown-item {
    padding: .5rem 1rem;
}

.staff-action-main-wraper button::after {
    display: none;
}

.staff-action-main-wraper button svg {
    font-size: 20px;
}

.staff-action-main-wraper .dropdown-item svg {
    font-size: 20px;
}

.staff--profile img{
    height: 150px;
    width: 150px;
    border-radius: 100%;
    object-fit: cover;
}

/* .staff--wrapper .table-responsive{
    overflow-x: visible;
} */

.tb-staff .table-responsive {
    height: 100vh;
}

@media (max-width:450px) {
    .staff-main-wraper {
        display: block;
    }

    .main-wraper-staff {
        background: #fff;
        padding: 20px 15px;
    }

    .staff-img {
        margin-right: 0px;
        margin-bottom: 13px;
    }
}

.add-pic-staff {
    text-align: center;
}