.seller-blogs-container {
    padding: 2rem 1rem;
}

.seller-blogs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 2rem;
    justify-items: center;
}

.seller-blog-card {
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.seller-blog-card:hover {
    transform: translateY(-5px);
}

.seller-blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.seller-blog-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.seller-blog-subtitle {
    font-size: 0.9rem;
    color: #6c757d;
}

.seller-blog-description {
    font-size: 0.95rem;
    line-height: 1.5;
}

.seller-blog-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.seller-blog-tag {
    background: #e9ecef;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    color: #495057;
}

.seller-blog-view-btn {
    width: 100%;
}

@media (max-width: 768px) {
    .seller-blogs-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 1rem;
    }
    
    .seller-blogs-container {
        padding: 1rem 0.5rem;
    }
}

.blogs-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.blogs-flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
}

.blog-card {
    flex: 1 1 calc(33.333% - 20px); 
    /* max-width: 350px; */
    height:350px;
    /* min-width: 350px; */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-description {
    
    width:100%;
    
    
    
}

.tags-container {
    margin-top: 10px;
}

.tag {
    display: inline-block;
    background: #e9ecef;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    margin-right: 5px;
    margin-bottom: 5px;
}

.view-blog-btn {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #73a5b5;
    color: white;
    border: none;
}

@media (max-width: 768px) {
    .blog-card {
        flex: 1 1 calc(50% - 20px); 
    }
    .blogs-flex-container {
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .blog-card {
        flex: 1 1 100%; 
    }
}
