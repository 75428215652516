.pagination {
  overflow: auto;
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 1rem;
}

.pagination-item {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 0.5px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.pagination-item:hover {
  background-color: #f1f1f1;
}

.pagination-item.active {
  background-color: #d1eff9;
  color: black;
  border-color: #d1eff9;
}

.pagination-item.disabled {
  pointer-events: none;
  color: #ccc;
  border-color: #ccc;
}

.pagination-item.dots {
  cursor: default;
}

.pagination-item svg {
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.pagination-item.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: spin 1s linear infinite;
  font-size: 20px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}