.popular--Wrapper.edit--page {
  padding-bottom: 0;
  padding-top: 0;
}

.sidebar--wrapper {
  display: flex;
  position: relative;
  flex-flow: row wrap;
}

#nav--sidebar {
  background: #3e3e3e;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 250px;
  position: relative;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

#nav--sidebar .navbar {
  padding: 0;
  flex-flow: column wrap;
  align-items: flex-start;
  background: #3e3e3e;
  /* height: 100%; */
  justify-content: flex-start;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
  /* overflow-y: auto; */
  /* flex: 0 0 250px; */
  max-width: 250px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  /* scrollbar-width: thin; */
  /* scrollbar-color: #191b1f transparent; */
}

#nav--sidebar .navbar-collapse {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn--Click,
.button--Toggle {
  position: absolute;
  left: 250px;
  top: 10px;
  /* z-index: 999999; */
  z-index: 999;
  border: 0;
  background: #3e3e3e;
  color: #ffffff;
  font-size: 35px;
  width: 40px;
  height: 40px;
  line-height: 30px;
  border-radius: 0 0.25rem 0.25rem 0;
  -webkit-border-radius: 0 0.25rem 0.25rem 0;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
}

.btn--Click:focus-visible,
.button--Toggle:focus-visible {
  outline: none;
}

.button--Toggle {
  left: 0;
}

#nav--sidebar.collapse--Nav {
  transform: translateX(-100%);
  min-width: 0;
}

#nav--sidebar .navbar-expand-md .navbar-nav {
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  padding: 1rem;
  font-size: 1rem;
  font-weight: normal;
  margin-left: 0;
  width: 100%;
  border: 0;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link svg {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link:hover,
#nav--sidebar .navbar-light .navbar-nav .nav-link.active,
#nav--sidebar .navbar-light .navbar-nav .nav-link.show,
#nav--sidebar .navbar-light .navbar-nav .nav-item a:hover,
#nav--sidebar .navbar-light .navbar-nav .nav-item a:focus {
  color: black;
  background-color: #d7ecf0;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link:hover svg,
#nav--sidebar .navbar-light .navbar-nav .nav-link.active svg,
#nav--sidebar .navbar-light .navbar-nav .nav-link.show svg,
#nav--sidebar .navbar-light .navbar-nav .nav-item a:hover svg,
#nav--sidebar .navbar-light .navbar-nav .nav-item a:focus svg {
  fill: #000000;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link-active {
  color: black !important;
  background-color: #d7ecf0;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link-active span {
  color: #000000;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link-route {
  color: #ffffff;
}

#nav--sidebar .navbar-expand-md .navbar-nav .dropdown-menu {
  position: static;
  background: #383839;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0;
  padding: 0.5rem 1rem;
}

#nav--sidebar .navbar-light .navbar-nav .nav-item {
  width: 100%;
}

#nav--sidebar .navbar-light .navbar-nav .nav-item a {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-radius: 0;
  color: #ffffff;
}

#nav--sidebar .navbar-light .navbar-nav .show a.dropdown-toggle {
  color: #000000;
}

#nav--sidebar .navbar-light .navbar-nav .show a.dropdown-toggle svg {
  fill: #000000;
}

#nav--sidebar .navbar-light .navbar-nav .nav-item a svg {
  margin-right: 12px;
  fill: #ffffff;
  width: 20px;
  height: auto;
  vertical-align: middle;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link.active .nav--title,
#nav--sidebar .navbar-light .navbar-nav .nav-item a:hover .nav--title,
#nav--sidebar .navbar-light .navbar-nav .nav-item a:focus .nav--title {
  color: inherit;
}

#nav--sidebar .navbar-light .navbar-nav .nav--title:hover,
#nav--sidebar .navbar-light .navbar-nav .nav--title:active {
  color: black;
}

#nav--sidebar .navbar-light .navbar-nav .nav-link-active > .nav--title {
  color: black !important;
}

#nav--sidebar .navbar-light .navbar-nav .nav-item .nav-link-active svg {
  fill: #000000;
}

#nav--sidebar .navbar-light .navbar-nav .dropdown:after {
  position: absolute;
  right: 1rem;
  top: 18px;
  /* bottom: 0; */
  font-size: 0.65rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  content: "\221F";
  color: #ffffff;
  margin: auto;
}

#nav--sidebar .navbar-light .navbar-nav .dropdown.show:after {
  top: 1.35rem;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  color: #000000;
}

#nav--sidebar .navbar-light .navbar-nav .dropdown:hover:after {
  color: #000000;
}

#nav--sidebar .dropdown-menu .dropdown-item {
  color: #ffffff;
}

#nav--sidebar .dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

#nav--sidebar.collapse--Nav .navbar {
  flex: 0;
  max-width: 0;
}

.admin--content {
  flex: 1 1;
  padding: 3rem;
  background: #d1eff910;
  width: 100%;
  /* overflow-y: scroll; */
}

@media screen and (min-width: 768px) {
  .admin--content {
    flex: 1 1 100%;
    width: fit-content;
    max-width: calc(100% - 250px);
  }

  #nav--sidebar.collapse--Nav ~ .admin--content {
    max-width: calc(100% - 0px);
  }
}

.edit--page form [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

.edit--page .form-label {
  display: block;
  margin-bottom: 0.1rem;
}

.edit--page form [type="file"] + label {
  background: none;
  color: #d1eff9;
  border-radius: 5px;
  cursor: pointer;
  min-height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 1.5rem 1rem;
  margin: 0;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  /* height: 100%; */
  /* width: 100%; */
  justify-content: center;
  font-size: 1.3rem;
  border: 2px dashed #d1eff9;
}

.edit--page form [type="file"] + label:hover {
  background-color: #d1eff9;
  border: 2px dashed #d1eff9;
  color: #3e3e3e;
}

.edit--page .photo--grid ul li {
  position: relative;
}

.edit--page .photo--grid ul li .delete--img {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: red;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  font-size: 1rem;
}

.edit--page .photo--grid ul li img {
  height: 161px;
}

.edit--page .card {
  flex-flow: row wrap;
  align-items: center;
  padding: 1rem 1.5rem;
}

.edit--page span.card-img,
.edit--page .card-body {
  flex: 1 1;
}

.edit--page .card-body {
  padding: 0;
}

.edit--page span.card-img {
  order: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.edit--page span.card-img svg {
  background: #d1eff980;
  fill: #3e3e3e;
  padding: 0.5rem;
  width: 50px;
  height: 50px;
  border-radius: 0.25rem;
}

.css-13cymwt-control {
  background: #99999920;
}

.css-1fdsijx-ValueContainer {
  min-width: 120px;
}

.edit--page .card-title {
  font-weight: 500;
  margin: 0;
  font-size: 0.875rem;
  color: #3e3e3e;
  text-transform: uppercase;
}

.edit--page .card-text {
  font-size: 2rem;
  font-weight: 700;
}

.edit--page table {
  background: #ffffff;
}

.edit--page table .dropdown-toggle::after {
  display: none;
}

.edit--page table .dropdown-toggle {
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
}

.table thead th {
  border-bottom: 0;
  min-width: 140px;
}

.table thead th:first-child,
.table thead th:last-child {
  min-width: fit-content;
}

.sidebar--wrapper .navbar-light .navbar-brand {
  display: none;
}

.edit--page .time--radio input[type="radio"] {
  display: none;
}

.edit--page .custom--slot {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
}

.edit--page .time--radio label {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  border: 1px solid #3e3e3e20;
  color: #3e3e3e;
  margin-right: 0.5rem;
  background: #ffffff;
  display: inline-block;
  cursor: pointer;
}

.edit--page .time--radio input[type="radio"]:checked + label {
  background: #d1eff9;
  color: #333333;
  border: 1px solid #d1eff9;
}

.places--list {
  position: absolute;
  padding: 0.55rem;
  background-color: #f5f4f4;
  color: #333;
  /* width: 100%; */
  width: calc(100% - 30px);
  height: 220px;
  overflow: scroll;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding-bottom: 0px;
  z-index: 9;
}

.places--list span {
  display: block;
  cursor: pointer;
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: 15px;
}

.form-control:focus {
  box-shadow: none;
}

.sub--places--list h6 svg {
  position: inherit !important;
  font-size: 20px;
}

.sub--places--list h6 {
  font-size: 16px;
  text-transform: uppercase;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.7rem;
  align-items: center;
}

.input-container ul {
  margin-bottom: 0px;
}

.input-container:focus-within {
  box-shadow: 0 0 0 1px rgb(1, 51, 128);
}

.chips {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.chip {
  background-color: #cfe1ff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.chip span {
  color: #013380;
  font-size: 1rem;
}

.chip svg {
  font-size: 1.5rem;
  fill: #3270d1;
  cursor: pointer;
  transition: fill 0.2s;
  margin-top: -19px;
  margin-right: -9px;
}

.chip svg:hover {
  fill: #2857a3;
  transition: all 0.2s;
}

input {
  font-size: 1.6rem;
  background: transparent;
  border: none;
  outline: none;
}

.error-message {
  margin-top: 0rem;
  color: red;
  display: block;
  margin: 0;
}

input#tags {
  font-size: 18px;
}

label.form-label {
  padding-bottom: 3px;
}

.form-control {
  height: 50px;
  border: 0px;
  border-radius: 5px;
  background: #f6f4f4;
}

.form-control:focus {
  color: #495057;
  background-color: #eeeeee20;
  box-shadow: 0 0 0 1px #d1eff9;
}

textarea.form-control {
  border: 0px;
  border-radius: 5px;
}

.add-services {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 20px;
}

.form-services-add {
  width: 100%;
  padding: 0px 15px;
}

.remove-services-bnt button {
  background-color: red;
  color: #fff;
  border: 0px;
}

.remove-services-bnt {
  text-align: end;
}

.msl-input-wrp {
  background: #fff;
  height: 49px;
  border: 0px;
  border-radius: 0px;
}

.msl.false {
  background: #fff;
  border: 0px;
}

.msl-input {
  outline: none;
  margin: 11px !important;
}

.accordion > .card > .card-header {
  width: 100%;
}

.book-services-Btn {
  text-align: end;
}

.all-servicess-Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 15px;
  margin-bottom: 12px;
}

.popular--Wrapper .all-servicess-Wrap h2 {
  margin: 0;
  padding: 0;
}

.all-servicess-Wrap h2:after {
  display: none;
}

section.About--Wrapper {
  width: 100%;
}



span.error-msg {
  color: red;
}

.error-container {
  min-height: 1.5em; /* Adjust based on your error message height */
}

.form-control.input {
  height: 48px;
  border: 0px;
  padding-top: 10px;
}

.edit--page .photo--grid ul li img {
  height: 200px;
  width: 300px;
  object-fit: cover;
}

.image-upload-p {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.image-upload-p label {
  cursor: pointer;
  margin: 1rem 0 0;
}

.image-upload-p img {
  /* border-radius: 25px; */
  /* max-width: 230px; */
  border-radius: 50%;
  width: 'auto';
  height: 200px;
}

.image-upload-p .profile_picture {
  width: 200px;
  object-fit: cover;
}

.image-upload-p span.delete--img {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  background: #ffffff;
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 1px solid #eeeeee;
  cursor: pointer;
}

.edit--page .photo--grid ul li .delete--img {
  position: absolute;
  right: 9px;
}

.edit--page .photo--grid ul li {
  position: relative;
  /* width: 100px; */
  height: 200px;
  max-width: 243px;
}

.photo--grid ul li {
  flex: inherit;
  margin: 0 0.1rem;
}

.add-pic-staff .photo--grid ul li {
  position: relative;
  /* width: 100px; */
  height: 200px;
  max-width: 243px;
  width: 300px;
}

span.nav--count--badge {
  background-color: #fa3e3e;
  border-radius: 50%;
  color: #fff !important;
  padding: 3px 10px;
  font-size: 11px;
}

.edit--page~footer {
    display: none;
}

/* .navbar .dropdown-item svg {
    color: #ffffffeb;
} */
input[type="time"] {
  font-size: 17px;
}

label.start-time {
  background: #fff;
  width: 100%;
  padding: 12px 8px;
  padding-bottom: 10px;
  border-radius: 8px;
}

.day-select {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

label.end-time {
  background: #fff;
  width: 100%;
  padding: 12px 8px;
  padding-bottom: 10px;
  border-radius: 8px;
}

.form-b-h input[type="checkbox"] {
  margin-right: 15px;
  width: 28px;
  padding: 16px;
  height: 16px;
}

.day-select label {
  align-items: center;
  /* display: flex; */
  margin-right: 20px;
}

.main-b-h {
  width: 100%;
  margin-top: 15px;
}

.b-h-m-w {
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.button-p {
  text-align: center;
  margin-bottom: 10px;
  padding: 0.54rem 1.5rem;
  color: #444444;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 0.3rem;
  background-color: #d1eff9;
  border-color: #d1eff9;
}

.staff-page form [type="file"] + label {
  background-color: #d1eff9;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  min-height: 65px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  justify-content: center;
  font-size: 1.2rem;
  border: 2px dashed #d1eff9;
  margin-bottom: 30px;
}

.user-buyer-name {
  background-color: #e7e7e7;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #333;
  font-size: 19px;
  font-weight: 600;
  margin-right: 9px;
}

.my-profile-dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.my-profile-text {
  font-weight: 600;
  font-size: 15px;
  /* margin-left: 11px; */
}

.my-profile-page-main-wraper .navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  width: 255px;
}

.my-profile-text a:hover {
  color: #333;
}

.dropdown-item a:hover {
  color: #333;
}

.modal-dialog input.form-control {
  border: 1px solid #d5d4d4;
}

.select-time-slot {
  padding-left: 15px;
}

.services-page-all .card {
  height: auto !important;
}

.services-main-wraper .accordion {
  display: flex;
  width: 100%;
}

.services-main-wraper .card {
  width: 50%;
}

.services-main-wraper .collapse {
  width: 100%;
}

.services-main-wraper.accordion {
  width: 100%;
  height: 600px;
  overflow-y: scroll;
}

.edit-services-main {
  display: flex;
  justify-content: space-between;
}

.edit-services-main svg {
  font-size: 20px;
  margin-bottom: 15px;
  width: 50px;
}

.select-time-slot {
  padding-left: 15px;
  width: 100%;
}

.react-tagsinput-input {
  width: 100% !important;
}

.form-control.input {
  height: auto !important;
}

.css-13cymwt-control {
  border: 0px !important;
}

.css-1fdsijx-ValueContainer {
  height: 50px;
}

.sidebar--wrappera a:hover {
  color: #fff !important;
}

.nav-link a:hover {
  color: #fff !important;
}

.dropdown-item a svg {
  fill: #fff;
}

a.link-side-bar-full-width {
  width: 100%;
  padding: 5px 16px;
}

a.link-side-bar-full-width {
  width: 100%;
  padding: 9px 16px;
  margin-top: 4px;
}

.link-side-bar-full-width-sub .navbar-nav {
  display: flex;
  flex-direction: row !important;
}

.link-side-bar-full-width svg {
  margin-right: 10px;
  fill: #fff !important;
  color: #fff !important;
}

.nav-link-route a:hover {
  color: #fff !important;
}

a.link-side-bar-full-width:hover {
  background: rgba(255, 255, 255, 0.1);
}

.edit-service {
  text-align: center;
}

.edit-service span svg {
  cursor: pointer;
}

.start-time-b {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.end-time-b {
  background-color: #fff;
  padding: 10px 14px;
  border-radius: 5px;
}

.bisisnessHours input[type="checkbox"] {
  margin-right: 14px;
}

.active-sidebar-link {
  background: rgba(255, 255, 255, 0.1);
}

.admin--content .photo--grid,
.admin--content .photo--grid ul {
  column-gap: 8px;
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 1rem;
}

.admin--content .photo--grid ul li {
  margin: 0;
}

#nav--sidebar .navbar-light .navbar-toggler {
  box-shadow: none;
  border: 0;
}

.popular--Wrapper .refferal__page .card {
  height: auto;
  padding: 2rem;
  text-align: center;
  border-radius: 0.25rem;
  box-shadow: none;
}

.popular--Wrapper .refferal__page .card svg {
  font-size: 2.5rem;
}

.popular--Wrapper .refferal__page .affiliate__Card {
  background: #3e3e3e;
  color: #ffffff;
}

.popular--Wrapper .refferal__page .card-title {
  font-weight: 500;
  margin: 0;
  font-size: 1rem;
  color: #3e3e3e;
  text-transform: capitalize;
}

.popular--Wrapper .refferal__page .affiliate__Card .card-title {
  color: #ffffff;
}

.popular--Wrapper .refferal__page p.card-text {
  justify-content: center;
  margin: 0.5rem 0;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  min-height: max-content;
  font-size: 13px;
  order: 0;
  line-height: 1.2;
}

.rbc-day-slot .rbc-event-label {
  order: 1;
  margin-top: 0;
  font-size: 70%;
}

/*-=-==-=-
Media Css
-=-==-=-=-*/

@media screen and (max-width: 767px) {
  .btn--Click,
  .button--Toggle {
    display: none;
  }

  #nav--sidebar {
    min-width: 100%;
    padding: 0;
  }

  #nav--sidebar.collapse--Nav {
    transform: translateX(0);
    min-width: 100%;
  }

  .sidebar--wrapper {
    padding: 0 0 6rem;
  }

  .admin--content {
    padding: 2rem;
  }

  #nav--sidebar .navbar {
    height: auto;
    max-height: 100%;
    overflow-y: visible;
    flex-flow: row wrap;
    color: #ffffff;
    max-width: 100%;
    padding: 0;
  }

  .sidebar--wrapper .navbar-light .navbar-brand {
    color: #ffffff;
    padding-left: 1rem;
    display: block;
  }

  .sidebar--wrapper .navbar-toggler-icon {
    filter: invert(1);
    -webkit-filter: invert(1);
  }

  .table-responsive-sm {
    overflow-x: hidden;
    overflow: auto;
    min-width: 100%;
    max-width: 320px;
  }
}

@media screen and (max-width: 575px) {
  .admin--content {
    padding: 2rem 1rem;
  }

  .select-time-slot {
    padding-left: 0;
  }

  .modal-body .select-time-slot p {
    font-size: 21px;
  }

  .modal-body .main-wraper-service-book {
    border-right: 0;
    padding-right: 0;
  }
}