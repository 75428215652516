/* .custom-switch {
width:300px;
height:300px;
}
.custom-switch-id {
height:300px;
width:300px;
} */

.form-switch .form-check-input {
    width: 4em;
    height: 2em;
}