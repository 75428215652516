.terms-conditions-container {
    background-color: #fff7f7;
    padding: 50px;
}

.terms-conditions-wrapper {
    margin-bottom: 20px;
}

.terms-conditions-content {
    font-size: 1rem;
    margin-bottom: 10px;
}

.terms-conditions-content strong {
    display: block;
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: bold;
}