.outsideContainerHeader {
    background-color: #fdfcfc;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    height: auto;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.insideContainerColumnHeader {
    height: 100%;
    background-image: url('https://res.cloudinary.com/fureverhomed/image/upload/f_auto,f_avif/v1730090248/dopuoqfpmkwoa9ckom6v.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    color: white;
    padding: 40px 20px;
}

/* Trust badge responsive styles */
.trust-badge {
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    padding: 8px 16px;
    margin-bottom: 1rem;
}

/* Title responsive styles */
.hero-title {
    font-size: clamp(2rem, 5vw, 4rem) !important;
    margin-bottom: 1rem !important;
    padding: 0 10px;
}

/* Subtitle responsive styles */
.subtitle {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    max-width: 700px;
    width: 100%;
    color: white;
    margin-bottom: 20px;
    padding: 0 10px;
    font-family: 'Poppins', sans-serif !important;
}

.introduction {
    font-size: clamp(0.9rem, 2vw, 1rem);
    color: white;
    max-width: 700px;
    width: 100%;
    margin: 0 auto 30px;
    line-height: 1.5;
    padding: 0 10px;
    font-family: 'Poppins', sans-serif !important;
}

/* Button styles */
.btnPrimaryHeader {
    background-color: #add9e1 !important;
    color: black !important;
    font-family: 'Poppins', sans-serif !important;
    padding: 12px 24px;
    width: min(300px, 90%);
    font-size: clamp(0.9rem, 2vw, 1.1rem);
}

/* Trust indicators responsive styles */
.trust-indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    font-size: clamp(0.8rem, 1.5vw, 0.9rem);
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .outsideContainerHeader {
        min-height: 500px;
        padding: 10px;
    }

    .insideContainerColumnHeader {
        padding: 30px 15px;
    }

    /* Stack buttons vertically on mobile */
    .button-container {
        flex-direction: column;
        gap: 1rem;
    }

    .trust-indicators {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
    .outsideContainerHeader {
        min-height: 400px;
    }

    .hero-title {
        font-size: 1.8rem !important;
    }

    .subtitle {
        font-size: 1rem;
    }

    .btnPrimaryHeader {
        width: 100%;
        max-width: none;
        margin: 0.5rem 0;
    }
}

/* Landscape mode adjustments */
@media screen and (max-height: 500px) and (orientation: landscape) {
    .outsideContainerHeader {
        min-height: 450px;
    }

    .insideContainerColumnHeader {
        padding: 20px 15px;
    }
}

/* High-resolution screens */
@media screen and (min-width: 1500px) {
    .insideContainerColumnHeader {
        border-radius: 15px;
    }

    .hero-title {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .btnPrimaryHeader {
        background-color: #7FA1B3 !important;
        color: white !important;
    }
}