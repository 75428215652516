.custom-section2 {
    width: 100%;
    background-color: #fdfdfd;
    /* margin-bottom: 64px; */
}

.custom-section {
    width: 100%;
    background-color: #fdfdfd;
    /* margin-bottom: 64px; */
}

.image-container2 {
    max-width: 1405px;
    margin: 0 auto;
}

.image-container2 img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 0px -350px;
    display: block;
}


@media screen and (max-width:500px) {
    .image-container2 img {
        width: 100%;
        height: auto;
        object-fit: unset;
        object-position: unset;
    }
}

.image-container {
    max-width: 1405px;
    margin: 0 auto;
}

.image-container img {
    width: 100%;
    display: block;
}



.animated-title {
    padding-bottom: 0;
    opacity: 0;
    width: 450px;
    height: 100px;
    /* padding: 0 8px; */
    /* font-weight: 550; */
    transform: translateY(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.animated-title.enter {
    opacity: 1;
    transform: translateY(0);
}


.popular--Wrapper {
    max-width: 100%;
    padding: 3rem 0 3rem;
}

.skeleton-placeholder {
    background-color: #e0e0e0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 1rem;
}



.popular--Wrapper h2 {
    /* justify-content: center; */
    margin-bottom: 0px;
    /* padding-bottom: 24px; */
    display: flex;
    align-items: center;
    /* font-size: 2.2rem; */
    /* font-size: 1.5rem; */
    position: relative;
}

/* .popular--Wrapper h2:after {
    position: absolute;
    left: 0;
    bottom: 1rem;
    margin: auto;
    content: '';
    background: #d1eff9;
    width: 100px;
    height: 3px;
} */

.popular--Wrapper h2 a,
.popular--Wrapper h2 button {
    margin-left: auto;
    background: #d1eff9;
    color: #444444;
    padding: 0.3rem 0.6rem;
    border-radius: 0.3rem;
    font-size: 1rem;
    text-transform: uppercase;
}

.popular--Wrapper h2 a:hover {
    background: #B6D1BF;
    color: #444444;
}

.popular--Wrapper .card,
.About--Wrapper .card {
    /* height: 100%; */
    height: 300px;
    border: 1px solid #eeeeee;
    box-shadow: 1px 5px 20px -10px #00000030;
    -webkit-box-shadow: 1px 5px 20px -10px #00000030;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    overflow: hidden;
}

.popular--Wrapper .card .card--media,
.About--Wrapper .card .card--media {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 1.25rem;
    padding-bottom: 0;
}

.popular--Wrapper .card--media .card-title,
.About--Wrapper .card--media .card-title {
    flex: 1 1;
}

.popular--Wrapper .card .card--media .card-title small,
.About--Wrapper .card .card--media .card-title small {
    font-size: 12px;
    color: #000000;
}

.popular--Wrapper .card .card--media .card-link img,
.About--Wrapper .card .card--media .card-link img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 12px;
    display: block;
    object-fit: cover;
}

.popular--Wrapper .actions-buttons {
    position: absolute;
    width: 86%;
    bottom: 20px
}

.category-card {
    transition: background-color 0.3s ease;
    position: relative;
}

/* .category-card:hover {
    background-color: #e0f7ff;
    cursor: pointer;
} */

.bbservice__link {
    position: absolute;
    right: 14px;
}

.bbservice__list {
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
    /* align-items: center; */
    justify-content: center;
}

.bbservice__list li {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;
    max-width: calc(20% - 10px);
    margin-bottom: 1rem;
    border: 1px solid #eeeeee;
    box-shadow: 1px 5px 20px -10px #00000030;
    -webkit-box-shadow: 1px 5px 20px -10px #00000030;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
}

.bbservice__list li span {
    display: block;
    padding: 0.7rem 0 1rem 0;
    text-align: center;
}

.bbservice__list li img {
    object-fit: cover;
    width: 75px;
    /* width: 100%; */
    /* height: 220px; */
}

.slick-slider .slick-slide {
    padding: 0 5px 10px;
}

.slick-slider .slick-track {
    margin-left: 0;
    margin-right: 0;
}

.slick-slider .card-body.cursor-pointer {
    min-height: 155px;
}

.popular--Wrapper .slick-slide .card-title small {
    display: inline-block;
    font-size: 80%;
    margin-right: 0.5rem;
    color: #3e3e3e;
    font-weight: 400;
}

.popular--Wrapper .slick-slide .card-title small.card--desc {
    min-height: 0;
}

.popular--Wrapper .slick-slide .card-title small:last-child {
    margin-right: 0;
}

.popular--Wrapper .slick-prev,
.popular--Wrapper .slick-next {
    width: 40px;
    height: 40px;
    background: #d1eff9;
    border-radius: 100px;
    z-index: 1;
}

.popular--Wrapper .slick-prev:hover,
.popular--Wrapper .slick-prev:focus,
.popular--Wrapper .slick-next:hover,
.popular--Wrapper .slick-next:focus {
    color: #ffffff;
    opacity: 0.5;
    outline: none;
    background: #444444;
}

.popular--Wrapper .slick-prev {
    left: -15px;
}

.popular--Wrapper .slick-next {
    right: -15px;
}

.popular--Wrapper .slick-prev:before,
.popular--Wrapper .slick-next:before {
    font-size: 22px;
    color: #ffffff;
    opacity: 1;
}

.popular--Wrapper .slick-prev:before {
    content: '\2770';
}

.popular--Wrapper .slick-next:before {
    content: '\2771';
}

.slick-dots li button:before {
    opacity: .3;
    font-size: 10px;
    color: #d1eff9;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #d1eff9;
}

.popular--Wrapper .card:hover {
    box-shadow: 1px 5px 20px -10px #00000050;
    -webkit-box-shadow: 1px 5px 20px -10px #00000050;
}

.popular--Wrapper .card-img-top {
    width: 100%;
    object-fit: cover;
    height: 295px;
    object-position: center;
}

.popular--Wrapper .card-title,
.About--Wrapper .card-title {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
    width: 100%;
    /* height: 100%; */
}

.item__rating svg {
    margin-right: 0.15rem;
}

.item__rating .rated svg {
    color: orange;
}

.item__rating .not__rated svg {
    color: gray;
}

/* Define the half-rated star SVG */

.item__rating .half-rated svg {

    mask: linear-gradient(to left, transparent 50%, orange 50%);
    fill: orange;
}

.popular--Wrapper .card-title a.card-link {
    color: #cecece;
    /* display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    height: 100%; */
}

.popular--Wrapper .card-title small {
    display: block;
    font-size: 90%;
    color: #3e3e3e;
    font-weight: 400;
}

.popular--Wrapper .card-title hr {
    margin-top: auto;
}

.popular--Wrapper .card-title .badge {
    background: #E8E1D9 !important;
    color: #3e3e3e;
    margin-right: 0.2rem;
    vertical-align: text-top;
    padding: .25em .4em .15em;
}

.popular--Wrapper p.card-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popular--Wrapper .rating--stars,
.popular--Wrapper .miles--icon {
    display: flex;
    align-items: center;
}

.popular--Wrapper .miles--icon svg {
    margin-right: 0.3rem;
}

.popular--Wrapper .rating--stars svg {
    fill: goldenrod;
    margin-right: 0.1rem;
}

.popular--Wrapper .slick-slider .slick-slide .slide__cat {
    margin: 0 0.5rem;
    padding: 0.5rem;
    border: 1px solid #d1eff950;
    border-radius: 0.5rem;
}

.popular--Wrapper .slick-slider .slick-slide h4 {
    font-size: 1.2rem;
    padding: 1rem 0 0 1rem;
}

.popular--Wrapper .slick-slider .slick-slide img {
    object-fit: scale-down;
    width: 100%;
    height: 230px;
}

/*-=-=-=-
Single Page Css
=-===-=*/

.page--banner {
    position: relative;
    padding: 5rem 0 4rem;
    background-size: cover;
    text-align: center;
}

.page--banner form {
    max-width: 700px;
    width: 100%;
}

.page--banner .search__results {
    max-width: calc(700px - 40px);
    width: 100%;
    margin-top: -1px;
    padding-top: 1rem;
}

.page--banner::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    content: '';
}

.page--banner h1,
.page--banner h2,
.page--banner h3,
.page--banner h4,
.page--banner h5,
.page--banner h6,
.page--banner p,
.page--banner img {
    color: #ffffff;
    position: relative;
}

.page--banner h1 svg {
    width: 34px;
    cursor: pointer;
    height: auto;
}

.page--banner .business--name {
    position: absolute;
    cursor: pointer;
    float: left;
}

.page--banner .business--name {
    display: flex;
    justify-content: center;
    align-content: space-between;
}

.page--banner img {
    /* width: 250px; */
    height: 230px;
    object-fit: scale-down;
    /* object-fit: cover; */
    margin: auto auto 1rem;
    border-radius: 25px;
    border: 3px solid #d1eff980;
}

.page--banner button {
    position: relative;
}

.tab-content {
    padding: 1rem 0 0;
    font-weight: 400;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-link {
    margin-bottom: 0px;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-radius: .25rem;
    color: #1e1e1e;
    margin-right: 0.5rem;
    background-color: #d1eff950;
}

.nav-tabs .nav-link:last-child {
    margin-right: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #1e1e1e;
    background-color: #d1eff9;
    border-color: transparent;
}

.main-about-section-buyer .photo--grid ul {
    display: flex;
    flex-flow: inherit;
}

.main-about-section-buyer .photo--grid ul li img {
    width: 330px;
    height: 100%;
    object-fit: cover;
}

.popular--Wrapper .card:hover {
    cursor: pointer;
}

span.rating-num {
    margin-left: 10px;
    margin-top: 13px;
    line-height: 0;
}

.review-01 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
}

.review-01 h4 {
    font-size: 19px;
    font-weight: 600;
}

.review-pic-wraper img {
    width: 154px;
    height: 154px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

label.add-photo-review.form-label {
    font-size: 17px;
    font-weight: 600;
    margin: 19px;
}

section.popular--Wrapper.deals--wrapper.main-wraper-add-review {
    padding-top: 65px;
    padding-bottom: 65px;
    flex: 1;
}

section.popular--Wrapper.deals--wrapper.main-wrapper-booking-modal {
    padding-top: 0px;
    padding-bottom: 0px;
}

.add-review-left-side img {
    width: 450px;
    height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
}

p.heading-add-review {
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 4px;
    font-size: 17px;
}

.main-wraper-add-review .photo--grid.profile-photo-staff {
    padding-left: 15px;
    padding-right: 15px;
}

.time-book-service {
    display: flex;
    justify-content: space-between;
}

.pay-ment-book-main {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bfbfbf;
    padding-bottom: 20px;
}

.book-img-amount-text {
    display: flex;
}

.book-img-amount-text img {
    width: 47px;
    height: 47px;
    border-radius: 50px;
    margin-right: 16px;
    border: 1px solid #333;
    padding: 2px;
}

.time-book-service h3 {
    font-size: 22px;
}

.time-book-service h2 {
    font-size: 18px;
}

.book-img-amount-text h4 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 1px;
}

.book-img-amount-text p {
    font-size: 15px;
}

.main-wraper-service-book {
    border-right: 2px solid #dbd1d1;
    padding-right: 20px;
}

.form-book-service {
    padding-left: 0px;
    margin-left: -15px;
    padding-top: 25px;
}

.checkbox-service-book {
    margin-left: 32px;
}

.checkbox-service-book label {
    color: #333 !important;
}

.book-service-pay {
    padding-left: 15px;
    padding-top: 31px;
}

.main-wraper-tabs {
    position: relative;
    /* padding: 0; */
}

.modal-body .main-wraper-tabs {
    padding: 0;
}

.main-cart-icon {
    position: absolute;
    top: -9px;
    right: 0px;
}

.main-cart-icon svg {
    font-size: 25px;
}

.circle-cart {
    width: 25px;
    height: 25px;
    background: #d1eff9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -16px;
    right: -13px;
    font-size: 13px;
    cursor: pointer;
}

.circle-cart span {
    margin-bottom: 0px;
    font-weight: 600;
}

/*-=-=-=-
Discover Page-20-02-2024=-=-
-=-=-=-=*/

/* .filter__sidebar {
    padding: 1rem;
    border: 1px solid #dddddd60;
    border-radius: 0.5rem;
    background: #ffffff;
    max-height: 700px;
    overflow-y: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: sticky;
    position: -webkit-sticky;
    top: 75px;
}

.filter__sidebar h4 {
    border-bottom: 1px solid #dddddd80;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.filter__sidebar h4 .btn-primary {
    padding: 0.25rem 0.75rem;
    color: #ffffff;
    font-size: 0.87rem;
    margin-left: auto;
    background: #444444;
    border: 0;
    text-transform: capitalize;
    font-weight: normal;
}

.filter__sidebar h4 .btn-primary:hover, .filter__sidebar h4 .btn-primary:focus,
.filter__sidebar h4 .btn-primary:focus-within {
    color: #000000;
    background: #d1eff9;
} */

.popular--Wrapper .accordion .card,
#discover--list .filter__sidebar .card {
    height: 100%;
    border: 0;
    border-bottom: 1px solid #dddddd60;
    padding-bottom: 0;
    margin-bottom: 1rem;
    box-shadow: none;
    border-radius: 0;
    background: none;
    overflow: visible;
    cursor: auto;
}

#discover--list .accordion .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

#discover--list .accordion .card-header svg {
    font-size: 14px;
}

#discover--list .accordion .list-group-item {
    padding: 0 0 .75rem 0;
    background-color: transparent;
    border: 0;
}

#discover--list .accordion .form-check {
    padding-left: 0;
}

#discover--list .accordion .form-check-label {
    cursor: pointer;
}

#discover--list .accordion .form-check input[type=checkbox]+label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 1.75rem;
}

#discover--list .accordion .form-check input[type=checkbox] {
    display: none;
}

#discover--list .accordion .form-check input[type=checkbox]+label::before {
    border: 1px solid #d1eff9;
    border-radius: 0.2rem;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
    color: transparent;
    transition: .2s;
    content: '';
}

#discover--list .accordion .form-check input[type=checkbox]+label::after {
    display: inline-block;
    position: absolute;
    left: 2.5px;
    font-size: 14px;
    top: 1px;
    color: transparent;
    transition: .2s;
    content: '';
}

#discover--list .accordion .form-check input[type=checkbox]:checked+label:after {
    content: "\2714";
    color: #fff;
}

#discover--list .accordion .form-check input[type=checkbox]:checked+label:before {
    background-color: #d1eff9;
}

/* #discover--list .filter__sidebar .card:last-child {
    margin: 0;
    border: 0;
} */

.location__search {
    width: 100%;
    /* max-width: 580px; */
    margin: auto;
}

.location__search form {
    background: #ffffff;
    box-shadow: 0 0 10px #33333320;
    padding: 1.2rem;
    margin-top: 1.8rem;
    margin-bottom: 1rem;
}

.location__search h4 {
    text-align: center;
    margin-top: -1.5rem;
}

.location__search h4 svg {
    font-size: 1.2rem;
    color: #d1eff9;
}

.location__search form h5 {
    display: flex;
    margin-bottom: 0.85rem;
    align-items: center;
}

.location__search form h5 .btn-primary {
    padding: 0.15rem 0.5rem;
    font-size: 0.95rem;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.location__search form h5 .btn-primary svg {
    vertical-align: middle;
    margin-right: 0.25rem;
}

.near__search span {
    display: block;
    cursor: pointer;
    padding: 10px 10px;
    border-bottom: 0.4px solid #e2dfdf;

}

.near__search span:last-child {
    border-bottom: none;
    /* Set border-bottom to none for the last child */
}

.modal .location__search {
    width: 100%;
    max-width: 100%;
    margin: 0 -15px;
}

.modal .filter__sidebar .location__search h4 {
    border-bottom: 0;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0.5rem;
    padding-bottom: 0;
    text-align: left;
}

.filter__sidebar .location__search form h5 span {
    display: none;
}

.search__results .heading-service h4 {
    font-size: 20px;
}

.search__results .accordion .card {
    height: 100%;
    border: 1px solid #eeeeee;
    box-shadow: 1px 5px 20px -10px #00000030;
    -webkit-box-shadow: 1px 5px 20px -10px #00000030;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    overflow: hidden;
    background: #ffffff;
    padding: 0;
}

.search__results .accordion .card-body {
    padding: 0;
}

.search__results .accordion .card-body .card-body {
    padding: 1.25rem;
}

.business__filter {
    z-index: 999999;
}

.business__filter .filter__sidebar .list-group {
    flex-direction: row;
    flex-wrap: wrap;
}

.business__filter .filter__sidebar .list-group-item {
    border: 0;
    padding: 0;
    flex: 1 1 100%;
    max-width: 50%;
    margin-bottom: 1rem;
}

.business__filter .filter__sidebar h4 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.business__filter .filter__sidebar .form-check {
    padding-left: 0;
}

.business__filter .filter__sidebar .form-check-label {
    cursor: pointer;
}

.business__filter .filter__sidebar .form-check input[type=checkbox]+label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
}

.business__filter .filter__sidebar .form-check input[type=checkbox] {
    display: none;
}

.business__filter .filter__sidebar .form-check input[type=checkbox]+label::before {
    border: 1px solid #d1eff9;
    border-radius: 0.2rem;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 1px;
    width: 22px;
    height: 22px;
    margin-right: 0.8rem;
    color: transparent;
    transition: .2s;
    content: '';
}

.business__filter .filter__sidebar .form-check input[type=checkbox]+label::after {
    display: inline-block;
    position: absolute;
    left: 5px;
    font-size: 14px;
    top: 2px;
    color: transparent;
    transition: .2s;
    content: '';
}

.business__filter .filter__sidebar .form-check input[type=checkbox]:checked+label:after {
    content: "\2714";
    color: #fff;
}

.business__filter .filter__sidebar .form-check input[type=checkbox]:checked+label:before {
    background-color: #d1eff9;
}

/*-=-=-=-
For Business radios-=
-=-=-=*/

.business__filter .filter__sidebar .business__list {
    column-gap: 10px;
}

.business__filter .modal-body {
    max-height: 630px;
    overflow-y: auto;
}

.business__filter .modal-body .clear__button {
    position: sticky;
    position: -webkit-sticky;
    bottom: -1rem;
    right: 0;
    background: #ffffff;
    padding: 1rem 0;
    text-align: center;
    z-index: 111;
}

.business__filter .filter__sidebar .business__list .list-group-item {
    border: 0;
    padding: 0;
    flex: 1 1;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
    border-radius: 50px;
}

.business__filter .filter__sidebar .business__list .form-check input[type=checkbox]+label {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 1rem 2rem;
    background: none;
    text-align: center;
    border: 1px solid #d1eff950;
    border-radius: 50px;
    z-index: 1;
}

#discover--list .accordion .form-check input[type=checkbox]:checked+label {
    background: #d1eff9;
}

.business__filter .filter__sidebar .business__list .form-check input[type=checkbox]:checked+label::before {
    display: inline-block;
    position: absolute;
    background: #d1eff9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-right: 0;
    border-radius: 50px;
    color: transparent;
    transition: .2s;
    content: '';
    z-index: -1;
}

.business__filter .filter__sidebar .business__list .form-check input[type=checkbox]+label::after,
.business__filter .filter__sidebar .business__list .form-check input[type=checkbox]+label::before {
    display: none;
}

.business__filter .language__list .list-group-item {
    flex: 1 1 100%;
    max-width: 33.333%;
}

/*=-=-=-=-=-=
Media css=-=-=-*/

@media screen and (max-width:1199px) {
    /* .animated-title {
        width: 450px;
    } */
    .popular--Wrapper .slick-prev {
        left: auto;
        right: 50px;
    }

    .popular--Wrapper .slick-next {
        right: 5px;
    }

    .popular--Wrapper .slick-prev,
    .popular--Wrapper .slick-next {
        top: auto;
        bottom: -60px;
    }

    .popular--Wrapper {
        padding: 3rem 0 3rem;
    }

    .popular--Wrapper h2 {
        margin-bottom: 0px;
        width: 400px;
        /* padding-bottom: 24px; */
    }

    .App-footer {
        margin-top: 2rem;
    }

    .h1,
    h1 {
        font-size: 2rem;
    }

    .h2,
    h2 {
        font-size: 1.5rem;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .popular--Wrapper .card-img-top {
        height: 160px;
    }
}

@media screen and (max-width:991px) {

    .h1,
    h1 {
        font-size: 1.5rem !important;
    }

    .h2,
    h2 {
        font-size: 1.1rem !important;
    }

    .popular--Wrapper h2 {
        /* font-size: 1.1rem; */
    }

    .popular--Wrapper h2 a {
        min-width: 70px;
        text-align: center;
    }

    .popular--Wrapper .card-img-top {
        height: 220px;
    }

    .popular--Wrapper .slick-slider .slick-slide img {
        height: 200px;
    }

    section#discover--list {
        padding-top: 0;
    }

    .bbservice__list li {
        flex: 1 1 100%;
        max-width: calc(33.333% - 10px);
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:640px) {
    .popular--Wrapper .slick-slider .slick-slide img {
        object-fit: scale-down;
        width: 100%;
        height: 250px;
    }
}

@media screen and (max-width:575px) {

    /* .h1,
    h1 {
        font-size: 1.2rem;
    }
    .h2,
    h2 {
        font-size: 1.0rem;
    } */

    .company--details ul li {
        max-width: 100%;
    }

    .popular--Wrapper .slick-slider .slick-slide img {
        /* height: auto; */
        object-fit: scale-down;
        width: 100%;
        height: 250px;
    }

    .bbservice__list li img {
        height: auto;
    }

    .route--map {
        margin-bottom: 2rem;
    }

    .popular--Wrapper .card-img-top {
        height: auto;
    }

    .popular--Wrapper .slick-slider .card-body {
        padding: 0.5rem 1rem;
    }

    .main__booking__service button,
    .main__booking__service .btn-primary:disabled {
        width: 100%;
        opacity: 1;
        color: #ffffff;
        background-color: #d1eff9;
        border-color: #d1eff9;
    }

    .business__filter .filter__sidebar .business__list .list-group-item {
        flex: none;
    }

    .business__filter .filter__sidebar .business__list .form-check input[type=checkbox]+label {
        /* min-width: 120px; */
        padding: 0.75rem 1rem;
    }

    .bbservice__list li {
        flex: 1 1 100%;
        max-width: calc(50% - 10px);
        margin-bottom: 1rem;
    }

    .location__search h4 {
        text-align: left;
    }

    .location__search {
        margin: 0;
    }
}

@media screen and (max-width:414px) {
    .business__filter .filter__sidebar .list-group-item {
        max-width: 100%;
    }

    .bbservice__list li {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }
}