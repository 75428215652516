/* .main-bookmarks-wraper-box {
    overflow: hidden;
    box-shadow: 0 0 5px #1e1e1e10;
    border: 1px solid #1e1e1e10;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
}

.content-bookmark {
    padding: 20px 20px;
    background-color: #fff;
}

.remove-bookmarks {
    color: #b42318;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #d5d4d4;
    padding: 6px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
}

.content-bookmark p {
    margin-bottom: 6px;
}

.rating-bookmark {
    margin-bottom: 15px;
    margin-top: -7px;
}

.rating-bookmark span {
    margin-right: 3px;
} */

.appointments-main-wraper nav.mb-3.nav.nav-tabs {
    display: flex;
    justify-content: space-around;
}

.appointments-main-wraper .nav-tabs .nav-link {
    margin-bottom: 0px;
    border-bottom: 1px solid #dfd9d9;
    ;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-radius: 0px !important;
    color: #1e1e1e;
    margin-right: 0px !important;
    background-color: transparent !important;
    width: 50%;
    text-align: center;
}

.appointments-main-wraper .nav-tabs .nav-link.active {
    border-bottom: 3px solid #333;
}

.nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: transparent !important;
}

.card-box-s-img {
    width: 24%;
    margin-right: 30px;
}

.main-card-s-box {
    display: flex;
    align-items: center;
}

.card-box-s-content p {
    margin-bottom: 6px;
}

.main-card-services {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px #1e1e1e20;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.Appointment_Service-main-wraper:last-child .main-card-services {
    margin-bottom: 0;
}

.amount-services-card h4 {
    color: #b42318;
    font-weight: 600;
}

.status-services {
    /* background: #b42318; */
    padding: 5px;
    border-radius: 4px;
    font-size: 13px;
    text-align: center;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    width: 100px;
    margin: 0px 0px 12px auto;
}

.status--pending,
.status--upcoming {
    background: #F3A638;
    color: #FDFDFE;
}

.status--completed, .status--verified {
    background: #68C776;
    color: #FDFDFE;
}

.status-refund.status--refund,
.status--failed {
    background: #E25344;
    color: #FDFDFE;
}

.status--cancelled {
    background: #DDCFCF;
    color: #1e1e1e;
}

.status--initiated,
.status--refund_initiated {
    background: #3784e7;
    color: #FDFDFE;
}

.status--refund_processing {
    background: #ead865;
    color: #000;
}

.status--approved,
.status--accepted,
.status--progress {
    background: #88D8F0;
    color: #FDFDFE;
}

.appointment--datetime--status span {
    display: block;
}

.action-service-card {
    display: flex;
    justify-content: flex-end;
}

.action-service-card button {
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid #d7d7d7;
    font-size: 15px;
    font-weight: 500;
}

.amount-services-card {
    text-align: end;
}

.action-service-card button:hover {
    color: #333;
}

.show>.btn-success.dropdown-toggle {
    color: #333;
}

.main-wraper-a-p {
    text-align: center;
    margin-top: 5%;
}

.main-wraper-a-p h4 {
    font-size: 27px;
    margin: 17px 0px;
}

.main-wraper-a-p svg {
    font-size: 50px;
}

/* .bookmark-button-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bookmark-button-b button {
    padding: 6px 10px;
    font-size: 1rem;
}  */


/** ------------------ Book Mark ---------------------*/
.bookmark-main .card .remove{
    cursor: pointer;
}

.bookmark-main .card .card--media, .About--Wrapper .card .card--media {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 1.25rem;
    padding-bottom: 0;
}

.bookmark-main .card .card--media .card-link img{
    width: 80px;
    height: 80px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 12px;
    display: block;
    object-fit: cover;
}

.bookmark-main .card--media .card-title, .About--Wrapper .card--media .card-title .card-link, .bookmark-main .card--media .card-title, .About--Wrapper .card--media .card-title .card-link:hover{
    text-decoration: none;
    color: #000 !important;
}

.bookmark-main .card--media .card-title, .About--Wrapper .card--media .card-title .card-link .name{
    font-size: 18px;
    margin-bottom: 5px !important;
}

.bookmark-main .card--media .card-title, .About--Wrapper .card--media .card-title {
    flex: 1 1;
}

.bookmark-main .card-title, .About--Wrapper .card-title {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
    /* height: 100%; */
}

.bookmark-main .card--media .card-title, .About--Wrapper .card--media .card-title .card-link .services{
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}



@media screen and (max-width:575px) {
    .main-card-services, .main-card-s-box {
        flex-flow: row wrap;
    }

    .main-card-s-box {
        margin-bottom: 2rem;
    }

    .amount-services-card {
        text-align: left;
    }

    .card-box-s-img {
        width: 80%;
        margin-right: 0;
        margin: 0 auto 2rem;
    }

    .action-service-card {
        justify-content: flex-start;
    }
}