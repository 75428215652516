.modal__form {
    padding: 0 !important;
}

.modal__form .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 0;
    position: relative;
}

.modal__form .modal-content {
    border: 0;
    height: 100%;
    border-radius: 0;
}

.modal__form .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

.modal__form .modal-header {
    align-items: flex-start;
    justify-content: flex-end;
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 0;
    padding: 0;
    position: relative;
}

.modal__form .modal-header button {
    background: none;
    color: #555555;
    padding: 0;
    position: absolute;
    right: 1rem;
    line-height: normal;
    border-radius: 0;
    top: 0;
    font-size: 2rem;
    border: 0;
    z-index: 111;
}

.modal__form .modal-header button:hover,
.modal__form .modal-header button:focus {
    background: none;
    box-shadow: none;
    color: #444444;
    border: 0;
}

.signup--page {
    display:flex;
    flex:1;
    padding: 0;
}

.modal__form .login--Wrapper {
    flex: 1 1;
}

.login--Wrapper form {
    background: #ffffff;
    padding: 3rem;
    border-radius: 0 1rem 1rem 0;
    height: 100%;
    border: 1px solid #e1ece3;
}

.login--Wrapper .step--form {
    /* padding: 6rem 4rem; */
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

.login--Wrapper .step--form .form--fields {
    width: 100%;
}

.login--Wrapper form .image-upload-p img {
    /* border-radius: 25px; */
    /* width: 225px; */
    border-radius: 50%;
    width: auto;
    height: 200px;
}

.login--Wrapper .company--brand {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    background: #c2d8c545;
    height: 100%;
    border-radius: 0;
    padding: 4rem 2rem;
}

.login--Wrapper .company--brand img {
    max-width: 350px;
}

.login--Wrapper .company--brand span {
    color: #d1eff9;
    text-transform: uppercase;
    display: block;
    text-align: center;
    font-size: 3rem;
}

.login--Wrapper form label {
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    display: block;
    color: #444444;
}

.login--Wrapper form .form-control {
    padding: 0 1rem;
    height: 45px;
}

.login--Wrapper form .form-control:focus {
    box-shadow: none;
}

.login--Wrapper form .form--checkbox {
    display: flex;
    align-items: center;
}

.login--Wrapper form .form--checkbox a {
    margin-left: auto;
    color: #91ced8;
    text-decoration: underline;
    font-weight: 500;
}

.login--Wrapper form button {
    width: 100%;
}

.login--Wrapper p {
    /* text-align: center; */
    margin: 0;
    margin-top: 1rem;
}

.login--Wrapper p a {
    text-decoration: underline;
    color: #91ced8;
}

.login--Wrapper .list-group {
    text-align: center;
}

.login--Wrapper .list-group-item {
    margin-bottom: 0.3rem;
    border: 0;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
}

.login--Wrapper .list-group-item:last-child {
    margin-bottom: 0;
    border: 1px solid grey;
}

.login--Wrapper .list-group-item svg {
    margin-right: 0.5rem;
}

.login--Wrapper .list-group .ff--facebook {
    background: #3b5998;
    color: #ffffff;
}

.login--Wrapper .list-group .ff--google {
    background: #ffffff;
    color: #333333;
    border: 1px solid #3e3e3e;
}

.login--Wrapper .list-group .ff--apple {
    background: #000000;
    color: #ffffff;
}

.login--Wrapper .list-group .ff--facebook:hover,
.login--Wrapper .list-group .ff--google:hover,
.login--Wrapper .list-group .ff--apple:hover {
    opacity: 0.85;
}

.login--Wrapper form [type=file] {
    height: 0;
    overflow: hidden;
    width: 0;
}

.login--Wrapper form [type=file]+label {
    background: none;
    color: #d9b99b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    position: relative;
    transition: all 0.3s;
    border: 1px solid #d9b99b;
    vertical-align: middle;
}

.login--Wrapper form [type=file]+label:hover {
    background-color: #d9b99b;
    color: #3e3e3e;
}

.login--Wrapper form [type=file]+label svg {
    margin-right: 0.2rem;
    font-size: 1.2rem;
}


/*-=-==-=-=
Media css
==-==-=-=*/

@media screen and (max-width:991px) {
    .login--Wrapper .company--brand {
        padding: 2rem;
        border-radius: 1rem 1rem 0 0;
    }

    .login--Wrapper .company--brand span {
        font-size: 2rem;
    }

    .login--Wrapper .company--brand img {
        max-width: 90px;
    }

    .login--Wrapper form {
        border-radius: 0 0 1rem 1rem;
    }

    .login--Wrapper .step--form {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width:767px) {
    .login--Wrapper form {
        padding: 1.5rem;
    }

    .modal__form .modal-body {
        padding: 2rem 1rem;
    }

    .modal__form .modal-header button {
        right: 0.5rem;
    }
}