.carousel-container .carousel-component {
    position: relative;
}

.carousel-container .carousel-control-prev,
.carousel-container .carousel-control-next {
    position: absolute;
    bottom: 10px;
    /* Move it to the bottom */
    top: auto;
    /* Remove top positioning if it's set */
}

.carousel-container .carousel-control-prev {
    left: auto;
    /* Remove left positioning if it's set */
    right: 60px;
    /* Adjust this value based on your design */
}

.carousel-container .carousel-control-next {
    right: 10px;
    /* Place it on the right */
}

/* This is important to make sure the arrows are visible on all slides */
.carousel-container .carousel-control-prev,
.carousel-container .carousel-control-next {
    z-index: 1;
}

.carousel-container .carousel-control-prev-icon,
.carousel-container .carousel-control-next-icon {
    background-image: none;
    /* Remove the default background images */
    background-color: blue;
    /* Set your desired color */
    /* Create a new arrow with the :after pseudo-element */
}


/*  */

.contact-us-header-content {
    background-color: #EDF7F6;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
    padding: 50px;
}

.bottom-spacing {
    margin-bottom: 100px;
}

.why-container {
    background-color: #fff7f7;
    padding: 50px;
}

.carousel-container {
    background-color: aliceblue;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.carousel-description {
    padding-bottom: 16px;
}

.carousel-control-next,
.carousel-control-prev {
    color: navy;
    width: 100px;
    font-size: 25px;
}

.carousel-control-next:focus,
.carousel-control-prev:focus {
    color: navy;
    width: 100px;
    font-size: 25px;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
    color: navy;
    width: 100px;
    font-size: 25px;
}

.carousel-component {
    background-color: #fff7f7;
    max-width: 950px;
    width: 100%;
}

.carousel-wrapper {
    width: 950px;
}

.text-img-wrapper {
    display: flex;
    padding: 25px;
    /* flex-direction: column; */
}

.carousel-img {
    max-width: 100%;
    height: 350px;
    display: block;
}

.card-img-wrapper {
    /* max-width: 950px; */
}

/* .card-img-wrapper {

}
.carousel-img {
    max-width: 100%;
    height: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.carousel-main-img {
    width: 400px;
} */

.carousel-caption {
    position: unset !important;
}

.header-description-carousel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    width: 300px;
}

/* .carousel-item {
    display: flex !important;
    flex-direction: row;
    background-color: green;
} */

.section-header-center {
    margin-bottom: 64px;
}

.center-text {
    text-align: center;
}

.contact-us-why-content-reverse {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    /* justify-content: center; */
    flex-direction: row;
    justify-content: center;
}

.why-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}

.rightside-content {
    width: 100%;
    max-width: 500px;
}

.rightside-wrapper {
    padding-left: 64px;
    width: 500px;
    max-width: 100%;
}

.rightside-wrapper-reverse {
    padding-right: 64px;
    width: 500px;
    max-width: 100%;
}

/* .header-img {
    width: 500px;
    max-width: 100%;
} */

.header-img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* X-offset, Y-offset, Blur, Spread, Color */
    /* margin: 20px; */
    border-radius: 8px;
    /* Optional: if you want rounded corners */
    transition: box-shadow 0.3s ease-in-out;
    /* Optional: for a smooth transition on hover */
    width: 500px;
    max-width: 100%;
}

.header-img:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3), 0 12px 40px 0 rgba(0, 0, 0, 0.29);
    /* Larger shadow for hover effect */
}



.header-span {
    /* margin-left: 20px; */
}


@media (max-width: 1100px) {
    .contact-us-header-content {
        flex-direction: column-reverse;
    }

    .rightside-wrapper {
        margin-top: 16px;
        margin-left: 0px;
        padding-left: 0px;
    }

    .rightside-wrapper-reverse {
        margin-top: 16px;
        margin-left: 0px;
        padding-left: 0px;
    }

    .contact-us-why-content-reverse {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 800px) {

    .text-img-wrapper {
        flex-direction: column;
    }

    .header-description-carousel-wrapper {
        padding-left: 0;
        padding-top: 16px;
        width: auto;
    }
}

@media (max-width: 600px) {


    .header-main-text {
        font-size: 2rem;
    }
}

@media (max-width: 475px) {

    .header-main-text {
        font-size: 1.5rem;
    }
}

@media (max-width: 400px) {
    .header-img {
        width: 100%;
    }

    .header-main-text {
        font-size: 1.3rem;
    }
}

@media (max-width: 350px) {

    .header-main-text {
        font-size: 1rem;
    }
}