/* EventModal.css */

.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 21px;
}

.react-calendar__tile--active {
  background-color: #5b6e74 !important;
}

.description-form-group {
  margin-bottom: 1.5rem !important;
}

.custom-textarea {
  padding: 12px !important;
  border-radius: 8px !important;
  border: 1px solid #dee2e6 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  resize: vertical !important;
  min-height: 120px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-textarea:focus {
  border-color: #0d6efd !important;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25) !important;
  outline: none !important;
}

.custom-textarea::placeholder {
  color: #6c757d !important;
  opacity: 0.8 !important;
}

/* .step-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #212529;
} */


.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #343a40;
}

.step-indicator {
  font-size: 0.9rem;
  color: #6c757d;
  margin-left: 0.5rem;
}

.modal-body {
  padding: 2rem;
}

.step-content {
  padding: 1rem 0;
}

.step-label {
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.form-text.text-muted {
  font-size: 0.85rem;
}

.time-options {
  margin-top: 0.5rem;
}

.cursor-blocked {
  cursor: not-allowed;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.footer-button {
  margin: 0.5rem 0;
}

.ml-auto {
  margin-left: auto;
}