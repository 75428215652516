.profile--Wrapper {
    padding: 4rem 0;
}

.profile--Photo {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #55555530;
    padding: 2rem;
    flex-flow: column wrap;
    border-radius: 0.5rem;
}

.profile--Photo svg {
    font-size: 2.5rem;
    fill: #555555;
}

.profile--Photo span {
    color: #444444;
    background: #d1eff9;
    display: inline-block;
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
    margin-top: 0.3rem;
}

.profile--Photo:hover span {
    box-shadow: 0 1px 0 1px #55555530;
}

.modal-header {
    border-radius: 0;
    position: relative;
}

.modal-header button {
    background: none;
    color: #555555;
    padding: 0;
    position: absolute;
    right: 1rem;
    line-height: normal;
    border-radius: 0;
    top: 0;
    font-size: 2rem;
    border: 0;
    z-index: 111;
}

.modal-header button:hover,
.modal-header button:focus {
    background: none;
    box-shadow: none;
    color: #444444;
    border: 0;
}
.main-profile-buyer .profile-photo-staff img{
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}
/* .react-datepicker-wrapper input[type="text"] {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
} */
.main-profile-buyer .button-p {
    background: #d1eff9;
    padding: 10px 51px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}
.buyer-profile-pic {
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
}
.buyer-profile-pic img{
    border: 1px solid #000;
    padding: 5px;
}
p.profile-photo-text {
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 5px;
}
.buyer-profile-text {
    margin-top: 21px;
    margin-left: 18px;
}

.edit--img {
    position: absolute;
    left: 150px;
    top: 20px;
    background: #fff;
    /* border: 0.5px solid #000; */
    border-radius: 100%;
    /* padding: 4px; */
    width: 30px;
    height: 30px;
    /* align-items: center; */
    text-align: center;
}

.edit--img svg {
    border: 1px solid #000;
    border-radius: 100%;
    padding: 2px;
    height: 30px;
    width: 30px;
}