.privacy-policy-container {
    background-color: #fff7f7;
    padding: 50px;
}

.privacy-policy-wrapper {
    margin-bottom: 20px;
}

.privacy-policy-content {
    font-size: 1rem;
    margin-bottom: 10px;
}

.privacy-policy-content strong {
    display: block;
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: bold;
}
