/* Increase the height of the calendar rows */
/* .rbc-time-slot,
.rbc-day-slot .rbc-time-slot {
    height: 80px; 
} */
.rbc-timeslot-group {
    width: 100px
}

/* Adjust the height and font-size for better visibility on mobile */
/* .rbc-header,
.rbc-day-bg,
.rbc-month-row,
.rbc-time-header-cell {
    height: 50px; 
    font-size: 16px; 
} */

/* Adjust the calendar event size */
/* .rbc-event {
    font-size: 14px; 
    padding: 8px; 
} */

/* Ensure the calendar takes up full width on mobile */
.rbc-calendar {
    min-height: 580px;
    width: 100%
}

/* Adjust the width and height of calendar cells for month view */
.rbc-month-row {
    min-height: 100px;
}

.rbc-date-cell {
    padding: 12px;
    font-size: 14px;
}

/* Increase the width of the day header */
.rbc-header {
    min-width: 100px;
    /* Adjust width as needed */
    text-align: center;
}

.rbc-time-view {
    overflow-y: auto;
}

.rbc-time-content {
    position: unset !important;
    overflow-y: unset !important;
}

/* .rbc-time-header {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #ddd;
    overflow-y: auto;
    position: relative;
} */

/* Increase the height of time slots */
.rbc-time-slot {
    height: 60px;
    /* Adjust height as needed */
}


.custom-label {
    margin-bottom: 0px;
}

.custom-label-small-helper-text {
    margin-bottom: 12px;
}

.date__select {
    position: relative;
}

.date__select .react-calendar {
    margin-bottom: 0.5rem;
    position: absolute;
    left: 0;
    top: auto;
    width: 100%;
    z-index: 1;
}

.date__select .form-label {
    cursor: pointer;
    display: block;
    background: #f6f4f4;
    padding: .375rem 1rem;
    border-radius: 0.5rem;
    border: 0px;
    border-radius: 5px;
    margin: 0;
}

.date__select .form-control {
    margin-top: 0.5rem;
}

.modal-footer {
    justify-content: flex-start;
    padding: 0.75rem 0 0;
}

.list__modal {
    background: rgba(0, 0, 0, 0.85);
}

.list__modal .modal-body {
    max-height: 650px;
    overflow-y: auto;
}

/* Custom styling for the select input */
.time--options {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

/* Styling for the dropdown options */
.time--options option {
    padding: 0px 50px;
    background-color: #fff;
    color: #333;
    margin-bottom: 10px;
}

/* Styling for disabled options */
.time--options option[disabled] {
    background-color: #f0f0f0;
    cursor: not-allowed;
    color: #999;
}

.time--options option:checked {
    background-color: #3174ad;
    /* Change the background color of the selected option */
    color: #fff;
    /* Change the text color of the selected option */
}

.schedule--add--btn {
    float: right;
    margin-bottom: 10px;
    font-size: 12px;
}