.signup--step--profile--picture{
    text-align: center;
}

.signup--step--profile--picture img.profile_picture {
    border: 1px solid #ededed;
    border-radius: 50%;
    cursor: pointer;
}

.signup--step--upload--certificate {
    text-align: center;
    border: 0.5px dashed #b7aeae;
    border-radius: 5px;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
}  