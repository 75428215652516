

  .review-container {
    display: inline-block;
    /* justify-content: space-between; */
    border: 1px solid #000;
    text-decoration: none;
    border-radius: 15px;
    padding: 7px 20px;
    max-width: 350px;
    margin: 20px auto;
    /* text-decoration: none; */
    /* color: black; */
    background-color: white;
    transition: transform 0.2s, box-shadow 0.2s;
}
  
  .review-container:hover {
    /* transform: scale(1.05);        Adds a subtle zoom on hover */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds shadow on hover */
  }

  .review-container-link{
    display: flex;
    color:#000
  }
  
  .review-text {
    font-size: 16px;  /* Large text size */
    font-weight: bold; /* Bold text */
    display: flex;
    align-items: center;
  }
  
  .google-logo {
    font-size: 50px;  /* Adjust the logo size */
  }
  
  .stars {
    margin-left: 10px;
    display: flex;
  }
  